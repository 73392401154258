import { BaseComponent, SubmitReset } from "./base.js";
import Loading from "./loading.js"
import { text, LanguageSelector } from "./language.js";

class Settings extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
		this.handleSubmitInfo = this.handleSubmitInfo.bind(this);
		this.state = {
			state: "settings",
			password: "",
			confirm: "",
			phone: "",
			email: "",
			dentist: ""
		};
	}
	
	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmitPassword(event) {
		event.preventDefault();
		event.stopPropagation();
		if (this.state.password !== this.state.confirm) {
			this.throwError({status: 400, reason: "error-password-match"});
			return;
		}
		this.setState({state: "loading"}, () => {
			this.api.userPassword({password: this.state.password}, (data) => {
				this.setState({state: "settings"}, () => {
					this.throwError({status: 200, reason: "info-password-changed"});
				});
			}, (error) => {
				this.setState({state: "settings"}, () => {
					this.throwError(error);
				});
			});
		});
	}
	
	handleSubmitInfo(event) {
		event.preventDefault();
		event.stopPropagation();
		
		let data = {};
		if (this.state.phone)
			data.phone = this.state.phone.replace(/\s/g, "");
		if (this.state.email)
			data.email = this.state.email;
		if (this.state.dentist)
			data.dentist = this.state.dentist;
		
		if (Object.keys(data).length !== 0) {
			this.setState({state: "loading"}, () => {
				this.api.userInfo(data, (data) => {
					this.setState({state: "settings"}, () => {
						this.throwError({status: 200, reason: "info-information-changed"});
					});
				}, (error) => {
					this.setState({state: "settings"}, () => {
						this.throwError(error);
					});
				});
			});
		}
	}
	
	render() {
		switch(this.state.state) {
			case "settings":
				return <>
					<div style={{textAlign: "center"}}><LanguageSelector /></div>
					<br />
					<form onSubmit={this.handleSubmitPassword}>
						{text("text-change-password")}<br />
						<input name="password" type="password" className="input-text" placeholder={text("field-password")} onChange={this.handleChange} /><br />
						<input name="confirm" type="password" className="input-text" placeholder={text("field-confirm-password")} onChange={this.handleChange} /><br />
						<SubmitReset />
					</form>
					<br />
					<br />
					<form onSubmit={this.handleSubmitInfo}>
						{text("text-change-userinfo")}<br />
						<input name="phone" type="text" className="input-text" placeholder={text("field-phone")} onChange={this.handleChange} /><br />
						<input name="email" type="text" className="input-text" placeholder={text("field-email")} onChange={this.handleChange} /><br />
						<input name="dentist" type="text" className="input-text" placeholder={text("field-dentist")} onChange={this.handleChange} /><br />
						<SubmitReset />
					</form>
				</>;
			default:
				return <Loading />;
		}
	}
}

export default Settings;
