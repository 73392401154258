import { VERSION, DATE } from "./base";
import { Text } from "./language.js";

import React from "react";

class About extends React.Component {
	render() {
		return <>
			<Text name="text-about" version={VERSION} date={DATE} />
			<Text name="doc-research-info" />
			<Text name="doc-privacy-statement" />
			<Text name="doc-consent" />
		</>;
	}
}

export default About;
