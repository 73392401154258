//import { setCookie, getCookie } from "./util.js";
import { LANGUAGES, DEFAULT_LANGUAGE, FLAGS, STRINGS } from "./strings.js";

import React, {useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

import "./language.css";

function setLang(lang) {
	if (!LANGUAGES.includes(lang))
		lang = DEFAULT_LANGUAGE;
	const params = new URLSearchParams(window.location.search);
	params.set("lang", lang);
	window.location.search = params;
}

function getLang() {
	const params = new URLSearchParams(window.location.search);
	const lang = params.get("lang");
	if (!lang)
		return DEFAULT_LANGUAGE;
	return lang;
}

function text(name) {
	const idx = LANGUAGES.indexOf(getLang());
	const translations = STRINGS[name];
	if (translations === undefined)
		throw new Error("Translation not found '" + name + "'");
	return translations[idx];
}

function Text(props) {
	const [elem, setElem] = useState(0);
	useEffect(() => {
		try {
			setElem(text(props.name)(props));
		}
		catch (err) {
			setElem(text(props.name));
		}
	}, [props]);
	return <>{elem}</>;
}

class LanguageSelector extends React.Component {
	render() {
		const flag = FLAGS[LANGUAGES.indexOf(getLang())];
		const items = LANGUAGES.map((lang, i) => {
			return <Dropdown.Item
				href="#"
				key={lang}
				onClick={() => {
					setLang(lang);
				}}
			>
				<img src={FLAGS[i]} alt={lang} className="img-flag" />
			</Dropdown.Item>;
		});
		return <Dropdown as="span">
			<Dropdown.Toggle>
				<img src={flag} alt="lang" className="img-flag" />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{items}
			</Dropdown.Menu>
		</Dropdown>;
	}
}

export { setLang, getLang, text, Text, LanguageSelector };
