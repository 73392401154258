import About from "./about.js";
import Auth from "./auth.js";
import { BaseComponent } from "./base.js";
import Home from "./home.js";
import { getLang, text, Text, LanguageSelector } from "./language.js"
import Loading from "./loading.js";
import Questionnaire from "./questionnaire.js";
import Results from "./results.js";
import Settings from "./settings.js";
import Sponsors from "./sponsors.js";
import { setCookie, getCookie } from "./util.js";

import React from "react";
import { Button, Nav, Navbar } from "react-bootstrap";

import "./App.css";

class Site extends React.Component {
	constructor(props) {
		super(props);
		this.handleHomeClick = this.handleHomeClick.bind(this);
		this.handleQuestionnaireClick = this.handleQuestionnaireClick.bind(this);
		this.handleResultsClick = this.handleResultsClick.bind(this);
		this.handleAboutClick = this.handleAboutClick.bind(this);
		this.handleSettingsClick = this.handleSettingsClick.bind(this);
		this.handleFeedbackClick = this.handleFeedbackClick.bind(this);
		this.handleQuestionnaireSubmit = this.handleQuestionnaireSubmit.bind(this);
		this.state = {
			state: null,
			rid: null
		};
	}
	
	handleHomeClick() {
		this.setState({state: null});
	}
	
	handleQuestionnaireClick() {
		this.setState({state: "questionnaire"});
	}
	
	handleResultsClick() {
		this.setState({state: "results"});
	}
	
	handleAboutClick() {
		this.setState({state: "about"});
	}
	
	handleSettingsClick() {
		this.setState({state: "settings"});
	}
	
	handleFeedbackClick() {
		window.open(text("url-feedback")); 
	}
	
	handleQuestionnaireSubmit(rid) {
		this.setState({rid: rid}, () => {
			this.setState({state: "results-single"});
		});
	}
	
	navbar() {
		return <Navbar collapseOnSelect expand="lg" variant="dark" className="navbar">
			<Navbar.Brand></Navbar.Brand>
			<Navbar.Toggle />
			<Navbar.Collapse>
				<Nav className="mr-auto">
					<Nav.Link onClick={this.handleHomeClick}>{text("navi-home")}</Nav.Link>
					<Nav.Link onClick={this.handleQuestionnaireClick}>{text("navi-questionnaire")}</Nav.Link>
					<Nav.Link onClick={this.handleResultsClick}>{text("navi-results")}</Nav.Link>
					<Nav.Link onClick={this.handleSettingsClick}>{text("navi-settings")}</Nav.Link>
					<Nav.Link onClick={this.handleAboutClick}>{text("navi-about")}</Nav.Link>
					<Nav.Link onClick={this.handleFeedbackClick}>{text("navi-feedback")}</Nav.Link>
					<Nav.Link onClick={this.props.onLogout}>{text("navi-logout")}</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>;
	}
	
	layout(content) {
		return <>
			{this.navbar()}
			<div className="wrapper">
				<div className="content">
					{content}
				</div>
			</div>
		</>;
	}
	
	render() {
		switch(this.state.state) {
			case "questionnaire":
				return this.layout(<Questionnaire
					qname={"ques-" + getLang()}
					onSubmit={this.handleQuestionnaireSubmit}
					onError={this.props.onError}
				/>);
			case "results":
				return this.layout(<Results onError={this.props.onError} />);
			case "results-single":
				return this.layout(<Results
					state="single"
					rid={this.state.rid}
					onError={this.props.onError}
				/>);
			case "settings":
				return this.layout(<Settings onError={this.props.onError} />);
			case "about":
				return this.layout(<About />);
			default:
				return this.layout(<Home
					onError={this.props.onError}
					onQuestionnaireClick={this.handleQuestionnaireClick}
				/>);
		}
	}
}

class App extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleError = this.handleError.bind(this);
		this.state = {
			state: null,
			error: null,
			cookiesOk: false
		};
	}
	
	componentDidMount() {
		this.api.userGet(
			(data) => {
				this.setState({state: "logged_in"});
			},
			(error) => {
				if (error.status === 403)
					this.setState({state: "logged_out"});
				else
					this.handleError(error);
			}
		);
	}
	
	componentWillUnmount() {
		clearInterval(this.errorTimer);
	}
	
	hideError() {
		this.setState({error: null});
		clearInterval(this.errorTimer);
	}
	
	handleError(eobj) {
		this.setState({error: eobj}, () => {
			this.errorTimer = setInterval(() => {
				this.hideError();
			}, 5000);
		});
	}
	
	createError(eobj) {
		const color = eobj.status === 200 ? "#005400" : "#ff0000";
		return <div className="error" style={{color: color}} onClick={() => {
			this.hideError();
		}}>
			{text(eobj.reason)}
		</div>;
	}
	
	layout(content) {
		const eobj = this.state.error;
		const error = eobj ? this.createError(eobj) : null;
		return <div id="App">
			{content}
			{error}
		</div>;
	}
	
	stateLoading() {
		return <div className="wrapper">
			<div className="content">
				<Loading />
			</div>
		</div>;
	}
	
	stateLoggedOutContent() {
		if (this.state.cookiesOk || getCookie("cookies-ok") === "1") {
			return <Auth
				onSuccess={() => {
					this.setState({state: "logged_in"});
				}}
				onError={this.handleError}
			/>;
		}
		else {
			return <>
				<Text name="text-cookies" />
				<div style={{textAlign: "center"}}>
					<Button onClick={() => {
						setCookie("cookies-ok", "1");
						this.setState({cookiesOk: true});
					}}>{text("btn-accept-yes")}</Button>
					<Button onClick={() => {
						this.handleError({status: 400, reason: "error-no-cookies"});
					}}>{text("btn-accept-no")}</Button>
				</div>
			</>;
		}
	}
	
	stateLoggedOut() {
		return <div className="wrapper">
			<div className="content">
				<Sponsors select={["unioulu", "ppl", "vipuvoimaa", "eakr"]} />
				
				<div style={{float: "right"}}>
					<LanguageSelector />
				</div>
				<h1><Text name="h-digileap" /></h1>
				<p><Text name="text-welcome" /></p>
				<div style={{marginBottom: "50px"}}>
					<Text name={"doc-research-info"} />
					<Text name={"doc-privacy-statement"} />
				</div>
				
				{this.stateLoggedOutContent()}
				
				<div style={{marginTop: "100px"}}>
					<Sponsors select={["colgate", "dentview", "mehilainen", "kallio", "kuusamo", "lahitapiola", "tecinspire", "tuudo"]} />
				</div>
			</div>
		</div>;
	}
	
	stateLoggedIn() {
		return <Site
			onLogout={() => {
				this.setState({state: null}, () => {
					this.api.logout(
						(data) => {
							this.setState({state: "logged_out"});
						},
						(error) => {
							window.location.reload(false);
						}
					);
				});
			}}
			onError={this.handleError}
		/>;
	}
	
	render() {
		switch(this.state.state) {
			case "logged_out":
				return this.layout(this.stateLoggedOut());
			case "logged_in":
				return this.layout(this.stateLoggedIn());
			default:
				return this.layout(this.stateLoggedOut());
		}
	}
}

export default App;
