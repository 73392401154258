import { BaseComponent } from "./base.js"
import { text } from "./language.js";
import Loading from "./loading.js";
import { copy, timestamp, ParseHTML } from "./util.js";

import React from "react";
import { Button } from "react-bootstrap";
import { useTransition, animated } from "react-spring";

import "./questionnaire.css";

function Animation(props) {
	const dir = props.dir;
	const transitions = useTransition(props.children, {
		from: {opacity: 0, marginLeft: (dir ? "+" : "-") + "50px"},
		enter: {opacity: 1, marginLeft: "0px"},
		leave: {opacity: 0, marginLeft: (dir ? "-" : "+") + "50px"},
		delay: 100,
		exitBeforeEnter: true,
		config: {duration: 150}
	});
	return <>
		{transitions((style, item) => {
			style.width = "100%";
			style.overflow = "hidden";
			return <animated.div style={style}>
				{item}
			</animated.div>;
		})}
	</>;
}

class Progress extends React.Component {
	render() {
		return <>{this.props.idx}/{this.props.total}</>;
	}
}

class Question extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: null
		};
	}
	
	render() {
		const q = this.props.q;
		const answers = q.alist.map((a) => {
			const className = this.state.selected === a.name ? "btn-answer-selected" : "btn-answer";
			return <li className="li-answer" key={a.name}>
				<Button
					className={className}
					onClick={(event) => {
						this.setState({selected: a.name});
						this.props.onAnswer(q, a);
					}}
				>
					<ParseHTML html={a.astr} />
				</Button>
			</li>;
		});
		
		return <>
			<p><ParseHTML html={q.qstr} /></p>
			<ol className="ol-answers">
				{answers}
			</ol>
		</>;
	}
}

class Questionnaire extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleAnswer = this.handleAnswer.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			state: null,
			qdict: null,
			current: null,
			history: null,
			forward: true
		};
	}
	
	fillTreeReq(qdict, current, num) {
		const q = qdict[current];
		if (!q.num || q.num < num)
			q.num = num;
		else
			return;
		
		for (const a of q.alist) {
			const qnext = qdict[a.qnext];
			if (!qnext)
				continue;
			this.fillTreeReq(qdict, qnext.name, num+1);
		}
	}
	
	componentDidMount() {
		this.api.questionnaireGet(this.props.qname,
			(data) => {
				this.fillTreeReq(data.qdict, data.qfirst, 1);
				this.setState({
					qdict: data.qdict,
					total: Object.keys(data.qdict).length,
					current: {
						qnext: data.qfirst,
						adict: {},
						times: {
							start: timestamp(),
							end: null,
							questions: {}
						}
					},
					history: []
				}, () => {
					this.setState({state: "ready"});
				});
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	handleAnswer(q, a) {
		let history = this.state.history.slice();
		history.push(copy(this.state.current));
		let current = copy(this.state.current);
		current.qnext = a.qnext;
		current.adict[q.name] = a.name;
		current.times.questions[q.name] = timestamp();
		this.setState({
			current: current,
			history: history,
			forward: true
		});
	}
	
	handlePrev() {
		let history = this.state.history.slice();
		if (history.length === 0)
			return;
		let current = history.pop();
		this.setState({
			current: current,
			history: history,
			forward: false
		});
	}
	
	handleSubmit() {
		const adict = copy(this.state.current.adict);
		const times = copy(this.state.current.times);
		times.end = timestamp();
		this.setState({state: null}, () => {
			this.api.questionnairePost(
				this.props.qname,
				{adict: adict, times: times},
				(data) => {
					this.props.onSubmit(data.rid);
				},
				(error) => {
					this.throwError(error);
				}
			);
		});
	}
	
	render() {
		switch(this.state.state) {
			case "ready":
				const q = this.state.qdict[this.state.current.qnext];
				const prev = this.state.history.length === 0 ? null : <Button onClick={this.handlePrev}>{text("btn-prev")}</Button>;
				const progress = q ? <Progress idx={q.num} total={this.state.total} /> : <>&nbsp;</>;
				const content = q ?
					<Question onAnswer={this.handleAnswer} q={q} /> :
					<div style={{textAlign: "center"}}><Button onClick={this.handleSubmit}>{text("btn-submit")}</Button></div>;
				return <>
					{prev}
					<br />
					<br />
					<div style={{float: "right"}}>
						{progress}
					</div>
					<Animation dir={this.state.forward}>
						{content}
					</Animation>
				</>;
			default:
				return (<Loading />);
		}
	}
}

export default Questionnaire;
