import { Document, Video } from "./base.js";

const LANGUAGES = ["en", "fi", "se"];
const DEFAULT_LANGUAGE = "fi";
const FLAGS = ["static/icons/flag-uk.png", "static/icons/flag-fi.png", "static/icons/flag-se.png"];

function docConsentEn(props) {
	return <Document header="Consent document" file="static/doc/consent-en.pdf" open={props.open}>
		<h2>Consent document</h2>
		
		<p>I have been asked to participate in the Digital Leap in Oral Health – Towards a Virtual Reception study, which aims to develop a virtual service in oral health and its automation to promote oral health. I have read the study notice. If I want, I can ask clarifying questions and discuss them at lupaloikata@oulu.fi or with the National Coordinator, Dentist Marja-Liisa Laitala (marja-liisa.laitala@oulu.fi/+358 29 448 5423), or the study's contact person Katri Kukkola (katri.kukkola@oulu.fi/+358 50 350 4872). I feel I have received sufficient information about my rights, the purpose of the study and my participation in it, as well as the benefits and potential risks of participating in the study.</p>
		
		<p>I know that participation in the study is voluntary and that I have the right to refuse to participate in the study and later, if I so wish, to withdraw from the study or withdraw my consent without giving a reason. Refusal, withdrawal from the study or withdrawal of consent will not in any way affect the treatment I may need now or in the future. If I decide to withdraw my consent or withdraw from the study for some other reason, the data collected by the time of withdrawal can be used as part of the research data.</p>
		
		<p>I know that the data collected about me will be treated confidentially and will not be disclosed to third parties and will be archived according to my consent.</p>
		
		<p>By accepting and registering for the study application, I am indicating my consent to participate in the study.</p>
		
		The National Coordinator is <br />
		Marja-Liisa Laitala<br />
		Faculty of Medicine, <br />
		P.O. Box 5000, FI-90014 University of Oulu<br />
		+358 29 448 5423 / +358 40 678 6785<br />
		<a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
	</Document>;
}

function docConsentFi(props) {
	return <Document header="Suostumusasiakirja" file="static/doc/consent-fi.pdf" open={props.open}>
		<h2>ETÄTUTKITTAVAN SUOSTUMUSASIAKIRJA</h2>
		
		<p>Minua on pyydetty osallistumaan Suunterveyden digiloikka – kohti virtuaalista vastaanottoa tutkimukseen, jonka tarkoituksena on kehittää suunterveyden virtuaalipalvelua ja sen automatisointia suun terveyden edistämiseen. Olen lukenut tutkimusta koskevan tiedotteen Halutessani voin esittää tarkentavia kysymyksiä ja keskustella niistä osoitteessa lupaloikata@oulu.fi tai tutkimuksesta vastaavalta hammaslääkäriltä Marja-Liisa Laitalalta (marja-liisa.laitala@oulu.fi /+358 29 448 5423) tai tutkimuksen yhteyshenkilöltä Katri Kukkolalta (katri.kukkola@oulu.fi/ +358 50350 4872). Tunnen saaneeni riittävästi tietoa oikeuksistani, tutkimuksen tarkoituksesta ja siihen osallistumisesta sekä tutkimukseen osallistumisen hyödyistä ja mahdollisista riskeistä. </p>
		
		<p>Tiedän, että tutkimukseen osallistuminen on vapaaehtoista ja että minulla on oikeus kieltäytyä tutkimukseen osallistumisesta ja myöhemmin halutessani myös syytä ilmoittamatta keskeyttää tutkimus tai peruuttaa suostumus. Kieltäytyminen, osallistumisen keskeyttäminen tai suostumuksen peruuttaminen eivät vaikuta mitenkään mahdollisesti tarvitsemaani hoitoon nyt tai tulevaisuudessakaan. Jos päätän peruuttaa suostumuksen tai osallistuminen tutkimukseen keskeytyy jostain muusta syystä, keskeyttämiseen mennessä kerättyjä tietoja voidaan käyttää osana tutkimusaineistoa.</p>
		
		<p>Tiedän, että suuni ja hampaideni terveyttä koskevia tietoja voidaan etsiä ilmoittamani hammashoidon palveluntarjoajan (Kuusamon tai muun kunnan tai kaupungin, peruspalvelukuntayhtymä Kallion tai muun hammashoitoa tarjoavan säätiön, yhtymän tai yrityksen, kuten YTHS:n Mehiläisen, Terveystalon, Plusterveyden, Oral Hammaslääkäreiden, Coronarian tai Pihlajalinnan) potilasrekisteristä ja käyttää tässä tutkimuksessa.</p>
		
		<p>Tiedän, että minusta kerättyjä tietoja käsitellään luottamuksellisesti eikä niitä luovuteta ulkopuolisille ja ne arkistoidaan suostumukseni mukaan.</p>
		
		<p>Hyväksymällä ja rekisteröitymällä tutkimuksen sovellukseen osoitan suostumukseni osallistua tutkimukseen.</p>
		
		Tutkimuksesta vastaavana henkilönä toimii<br />
		Marja-Liisa Laitala<br />
		Lääketieteellinen tiedekunta,<br />
		PL 5000, 90014 Oulun yliopisto<br />
		+358 29 448 5423 / +358 40 678 6785<br />
		<a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
	</Document>;
}

function docConsentSe(props) {
	return <Document header="Samtyckesdocument" file="static/doc/consent-se.pdf" open={props.open}>
		<h2>Samtyckesdocument</h2>
		
		<p>Man har bett mig delta i undersökningen Munhälsans digitala språng – mot en virtuell mottagning, vars syfte är att utveckla och automatisera en virtuell tjänst för munhälsa för att främja munhälsa. Jag har läst anvisningarna om undersökningen. Om jag vill kan jag ställa fler frågor och diskutera dem på adressen lupaloikata@oulu.fi eller med tandläkaren Marja-Liisa Laitala som svarar för undersökningen (marja-liisa.laitala@oulu.fi /+358 29 448 5423), eller kontaktpersonen för undersökningen Katri Kukkola (katri.kukkola@oulu.fi/ +358 50 350 4872). Jag upplever att jag har fått tillräckligt med information om mina rättigheter, syftet med undersökningen och deltagandet i den, samt om fördelar och eventuella risker med att delta i undersökningen.</p>
		
		<p>Jag vet att det är frivilligt att delta i undersökningen och att jag har rätt att inte delta i undersökningen, och senare om jag så vill även utan orsak avbryta undersökningen eller återkalla samtycket. Att jag inte deltar, avbryter deltagandet eller återkallar samtycket påverkar inte på något sätt den vård som jag eventuellt behöver nu eller i framtiden. Om jag beslutar att återkalla samtycket, eller deltagandet i undersökningen avbryts av någon annan orsak, kan de uppgifter som insamlats före avbrytandet användas som del av forskningsmaterialet. </p>
		
		<p>Jag vet att uppgifter om min mun och mina tänder kan sökas i patientregistret för den tjänsteleverantör av tandvård som jag angett  (Kuusamo eller en annan kommun eller stad, samkommunen Peruspalvelukuntayhtymä Kallio, eller en annan stiftelse, sammanslutning eller företag som tillhandahåller tandvård såsom SHVS, Mehiläinen, Terveystalo, Plusterveys, Oral Hammaslääkärit, Coronaria eller Pihlajalinna) och användas i undersökningen.</p>
		
		<p>BJag vet att de uppgifter som samlats in om mig behandlas konfidentiellt, inte överlåts till utomstående och arkiveras i enlighet med mitt samtycke. </p>
		
		<p>Genom att godkänna och registrera mig till undersökningens applikation ger jag mitt samtycke till att delta i undersökningen.</p>
		
		Forskningsansvarig är<br />
		Marja-Liisa Laitala<br />
		Faculty of Medicine, <br />
		P.O. Box 5000, FI-90014 University of Oulu<br />
		+358 29 448 5423 / +358 40 678 6785<br />
		<a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
	</Document>;
}

function docImagingEn(props) {
	return <Document header="Imaging guide" file="static/doc/imaging-guide-en.pdf" open={props.open}>
		<p>These instructions guide you through how to take five different photos of your teeth with the main camera on your smartphone yourself or with the help of another person. The dental images may be vertical or horizontal.<b>For best results, use the 'light always on' flash option when taking your pictures.</b> If your phone's software does not support this, there are free camera apps available with this feature, such as Open Camera for Android and VSCO for iOS. The best way to ensure correct focusing of the dental photo is to touch the screen as indicated in the instructions before pressing the shutter button.</p>
		
		<h2>Taking dental photos yourself with a mirror</h2>
		<p>You can use a mirror to take all the dental photos yourself. Position yourself in front of the mirror so that you can see the phone screen through the mirror and see the image of the camera's viewfinder and the capture/shutter button on the screen. Note the camera's minimum focus distance; images taken too close are out of focus.</p>
		<div style={{textAlign: "center"}}><img src="static/doc/mirror.jpg" alt="mirror.jpg" style={{width: "90%"}} /></div>
		
		<h2>Taking dental photos</h2>
		<p>Next, take five (5) pictures of your teeth yourself through a mirror, or with the help of another person, as shown in the images below.</p>
		
		<h2>Front teeth</h2>
		<p>'Grimace image', where the front teeth are aligned (so that the upper teeth do not go in front of the lower teeth in the picture).</p>
		<p style={{textAlign: "center"}}><img src="static/doc/front-en.jpg" alt="front.jpg" style={{width: "80%"}} /></p>
		
		<h2>Side teeth</h2>
		<p>Take a picture of the facial (outer) parts of your teeth on both sides. You can use your fingers to stretch the corner of your mouth out.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/side-en.jpg" alt="side.jpg" style={{width: "80%"}} /></p>
		
		<h2>Upper teeth</h2>
		<p>The aim is to take a picture of the dental surfaces as directly as possible. The photo is easier to take if you can tilt your head back as much as feels feasible.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/upper-en.jpg" alt="upper.jpg" style={{width: "80%"}} /></p>
		
		<h2>Lower teeth</h2>
		<p>The aim is to take a picture of the dental surfaces as directly as possible. The head can be almost vertical.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/lower-en.jpg" alt="lower.jpg" style={{width: "80%"}} /></p>
	</Document>;
}

function docImagingFi(props) {
	return <Document header="Kuvausohjeet" file="static/doc/imaging-guide-fi.pdf" open={props.open}>
		<p>Tämä ohje opastaa, miten otat viisi erillaista kuvaa hampaistasi älypuhelimesi pääkameralla, itse tai toisen henkilön avustamana. Hammaskuvat voivat olla pystyyn tai vaakaan kuvattuna. <b>Parhaat tulokset saat, kun käytät kuvauksessa jatkuvasti päällä olevaa salamavaloa.</b> Jos puhelimesi ohjelmisto ei tue tätä, ilmaisia kamerasovelluksia on saatavilla tällä ominaisuudella, esim. Androidille Open Camera, ja iOS:lle VSCO. Parhaiten varmistat hammaskuvan oikean tarkennuksen koskettamalla näyttöä ohjeen mukaisesta kohdasta ennen kuvauslaukaisupainikkeen painamista.</p>
		
		<h2>Hammaskuvien kuvaaminen itse peilin avulla</h2>
		<p>Voit ottaa itse kaikki hammaskuvat peilin avulla. Asetu peilin eteen riittävän lähelle niin, että näet peilin kautta puhelimen näytön ja näet näytöltä kameran etsimen kuvan ja kuvanotto-/laukaisinpainikkeen. Huomio kameran lähin tarkennusetäisyys, liian läheltä otettu kuva on epätarkka.</p>
		<div style={{textAlign: "center"}}><img src="static/doc/mirror.jpg" alt="mirror.jpg" style={{width: "90%"}} /></div>
		
		<h2>Hammaskuvaukset</h2>
		<p>Ota seuraavaksi alla olevien kuvien mukaiset viisi (5) kuvaa hampaistasi itse peilin kautta, tai toisen henkilön avustuksella.</p>
		
		<h2>Etuhampaat </h2>
		<p>'Irvistyskuva', jossa etuhampaat ovat hampaan kärjet yhdessä (niin että ylähampaat eivät mene alahampaiden eteen kuvassa).</p>
		<p style={{textAlign: "center"}}><img src="static/doc/front-fi.jpg" alt="front.jpg" style={{width: "80%"}} /></p>
		
		<h2>Hampaiston ulkosivut</h2>
		<p>Ota kuva molemmin puolin hampaiston sivuosista, voit venyttää sormin suupieltä ulommas. </p>
		<p style={{textAlign: "center"}}><img src="static/doc/side-fi.jpg" alt="side.jpg" style={{width: "80%"}} /></p>
		
		<h2>Ylähampaiston kuva</h2>
		<p>Pyritään ottamaan kuva niin suoraan hammaspinnoista kuin mahdollista, kuvaus on helpompi tehdä, jos voit kääntää päätäsi sen verran taaksepäin kuin sopivalta tuntuu.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/upper-fi.jpg" alt="upper.jpg" style={{width: "80%"}} /></p>
		
		<h2>Alahampaiston kuva</h2>
		<p>Pyritään ottamaan kuva niin suoraan hammaspinnoista kuin mahdollista, pää saa olla miltei pystysuorassa. </p>
		<p style={{textAlign: "center"}}><img src="static/doc/lower-fi.jpg" alt="lower.jpg" style={{width: "80%"}} /></p>
	</Document>;
}

function docImagingSe(props) {
	return <Document header="Fotograferingsanvisningar" file="static/doc/imaging-guide-se.pdf" open={props.open}>
		<p>Denna anvisning beskriver hur du själv eller en annan person tar fem olika bilder på dina tänder med huvudkameran på din smarttelefon. Bilderna kan tas vertikalt eller horisontalt.<b>De bästa bilderna får du om du använder blixtljus som är på hela tiden.</b> Det finns avgiftsfria kameraapplikationer med denna egenskap, till exempel Open Camera för Android och VSCO för iOS, om din kamera inte har denna egenskap. Bäst säkerställer du bildens rätta skärpa genom att peka på skärmen på det ställe på tandbilden som anvisningen visar innan du trycker på avtryckaren.</p>
		
		<h2>Fotografera tänderna själv med hjälp av en spegel</h2>
		<p>Du kan själv ta alla tandbilderna med hjälp av en spegel. Ställ/sätt dig framför spegeln tillräckligt nära så att du via spegeln ser telefonens skärm och på telefonens skärm ser sökarens bild och avtryckaren. Beakta kamerans skärpa. En bild som tas för nära blir suddig.</p>
		<div style={{textAlign: "center"}}><img src="static/doc/mirror.jpg" alt="mirror.jpg" style={{width: "90%"}} /></div>
		
		<h2>Fotografering av tänderna</h2>
		<p>Ta nu fem (5) bilder enligt bilderna nedan på dina tänder själv med hjälp av en spegel eller låt en annan person göra det.</p>
		
		<h2>Framtänderna</h2>
		<p>En 'grimasbild' där du biter ihop framtänderna (så att tänderna i överkäken inte hamnar framför tänderna i underkäken).</p>
		<p style={{textAlign: "center"}}><img src="static/doc/front-se.jpg" alt="front.jpg" style={{width: "80%"}} /></p>
		
		<h2>Tändernas yttre sidor</h2>
		<p>Ta en bild på båda sidorna av käken. Du kan dra ut mungipan med fingrarna.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/side-se.jpg" alt="side.jpg" style={{width: "80%"}} /></p>
		
		<h2>Bild på tänderna i övre käken</h2>
		<p>Sträva efter att ta bilden så rakt på tandytorna som möjligt. Det är lättare att ta bilden om du kan vända ditt huvud lite bakåt.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/upper-se.jpg" alt="upper.jpg" style={{width: "80%"}} /></p>
		
		<h2>Bild på tänderna i underkäken</h2>
		<p>Sträva efter att ta bilden så rakt på tandytorna som möjligt. Håll huvudet nästan upprätt.</p>
		<p style={{textAlign: "center"}}><img src="static/doc/lower-se.jpg" alt="lower.jpg" style={{width: "80%"}} /></p>
	</Document>;
}

function docPrivacyStatementEn(props) {
	return <Document header="Privacy statement" file="static/doc/privacy-statement-en.pdf" open={props.open}>
		<h2>Information for Research Participants</h2>
		<p>You are taking part in a scientific study organised by the University of Oulu. This notice describes how your personal data will be processed in the study. </p>
		
		<p>Participation in the study is voluntary. There will be no negative consequences for you if you choose not to participate in the study or if you withdraw from the study. However, if you withdraw from the study, data collected prior to your withdrawal may still be used in the study.* For more information on your rights and how you can affect the processing of your personal data, please see section 17 of this notice.</p>
		
		<p>If you withdraw from the study or withdraw your consent to participate in the study, the data collected prior to either withdrawal may still be used in the study unless you specifically request the erasure of your data or the restriction of its processing in accordance with the rights described in section 17. </p>
		
		<h2>1.Data Controller</h2>
		<p>The data controller of the study is the University of Oulu, which is responsible for the lawfulness of the processing of personal data taking place in connection with the research.</p>
		
		<p>Address: University of Oulu, P.O. Box 8000, FI-90014 University of Oulu (Pentti Kaiteran katu 1, Linnanmaa) </p>
		
		Contact person responsible for matters relating to the research:<br />
		Name: Katri Kukkola<br />
		Address: Optoelectronics and Measurement Techniques research unit, Faculty of Information Technology and Electrical Engineering, University of Oulu, P.O. Box 8000, FI-90014 University of Oulu (Erkki Koiso-Kanttilankatu 3, Linnamaa)<br />
		Telephone: +358 29 4487 694<br />
		E-mail: <a href="mailto:katri.kukkola@oulu.fi">atri.kukkola@oulu.fi</a><br />
		E-mail: <a href="lupaloikata@oulu.fi">lupaloikata@oulu.fi</a><br />
		<br />
		
		<h2>2. Description of the study and the purposes of processing personal data</h2>
		
		<p>The aim of the study is to enable the use of remote services in oral health care, especially in preventive work. The study prepares and tests a concept and tool for a remote service. The study examines whether a remote oral health care service based on virtually collected data and machine learning is capable and suitable as a tool for identifying the risks of the most common oral diseases and dental phobia, customising self-care guidance and providing an initial recommendation for a treatment path without the participation of experts. </p>
		
		<p>The purpose of processing personal data is to develop the risk assessment and prevention of oral diseases. The observations and conclusions made from the survey responses and dental images will be compared with oral and dental health data (oral health examination data) from the patient register by statistical methods and machine learning. The data collected in the study will be used to create a system based on machine learning (using artificial intelligence). The suitability and desirability of virtual services are examined by means of a separate survey. </p>
		
		<p>Direct personal data is used only for extracting patient records concerning oral health from the patient register (the extraction and linking is done by the Finnish Social and Health Data Permit Authority Findata). Only personal data necessary in view of the purpose of the study will be recorded in the study register. In the study, prior to analysis, direct personal identifiers will be removed, and documents and research results will only refer to you by an identifier code. When processing images, background information is removed and cropped so that the face is not visible. As a result, the results cannot be linked to an individual.</p>
		
		<h2>3. Parties and their responsibilities in research collaboration</h2>
		<p>The study is NOT a collaboration.</p>
		
		<h2>4. National Coordinator or responsible research group</h2>
		Name: Marja- Liisa Laitala<br />
		Address: Unit of Oral Health Sciences, Faculty of Medicine, P.o. Box 5000, 90014 University of Oulu (Aapistie 3, Kontinkangas)<br />
		Telephone: 029 4485 423<br />
		E-mail: <a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
		<br />
		
		<h2>5. Contact details of the Data Protection Officer</h2>
		<p>Contact details of the Data Protection Officer:: <a href="mailto:dpo@oulu.fi">dpo@oulu.fi</a>.</p>
		
		<h2>6. Persons performing the study</h2>
		<p>The research is carried out by researchers and research assistants of the Unit of Oral Health Sciences of the Faculty of Medicine of the University of Oulu, the Optoelectronics and Measurement Techniques research unit of the Faculty of Information Technology and Electrical Engineering, and the Biomimetics and Intelligent Systems Group.</p>
		
		<h2>7. Name, nature and duration of the study</h2>
		Name of the study: Digital Leap in Oral Health – Towards a Virtual Reception<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> One-time research<br />
		<input type="checkbox" disabled="disabled" /> Monitoring study<br />
		
		Duration of the study – how long your personal data will be processed for this study:<br />
		<p>The study is expected to last until the end of 2025, but it may be longer if required by the research-related publishing or doctoral thesis. It is also possible that further research according to the same purpose will be carried out on the basis of the data.</p>
		
		<h2>8. Lawful basis of processing</h2>
		Personal data is processed on the following basis, which is based on Article 6(1) of the General Data Protection Regulation: <br />
		<input type="checkbox" disabled="disabled" /> Participant's consent<br />
		<input type="checkbox" disabled="disabled" /> Compliance with a legal obligation to which the data controller is subject<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Scientific or historical research, statistics or the exercise of official authority in the public interest<br />
		<input type="checkbox" disabled="disabled" /> Legitimate interests pursued by the data controller or by a third party (if so, please specify:)<br />
		<br />
		
		<h2>9. Sensitive personal data</h2>
		The following sensitive personal data will be processed in the study:<br />
		<input type="checkbox" disabled="disabled" /> Racial or ethnic origin<br />
		<input type="checkbox" disabled="disabled" /> Political opinions<br />
		<input type="checkbox" disabled="disabled" /> Religious or philosophical beliefs<br />
		<input type="checkbox" disabled="disabled" /> Trade union membership<br />
		<input type="checkbox" disabled="disabled" /> Genetic data<br />
		<input type="checkbox" disabled="disabled" /> Biometric data for the purpose of uniquely identifying a natural person<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Health<br />
		<input type="checkbox" disabled="disabled" /> A natural person's sex life or sexual orientation<br />
		<br />
		Sensitive data is processed on the following basis, which is based on Article 9(2) of the General Data Protection Regulation:<br />
		<input type="checkbox" disabled="disabled" /> Consent of the participant<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Scientific or historical research purposes or statistical purposes<br />
		<input type="checkbox" disabled="disabled" /> The sensitive data has been made public by the participant<br />
		<input type="checkbox" disabled="disabled" /> Other: Please specify<br />
		<input type="checkbox" disabled="disabled" /> Personal data relating to criminal convictions or offences will be processed in the study.<br />
		<br />
		
		<h2>10. What personal data does the research data contain</h2>
		<p>The personal data collected in the study includes the participant's name, personal identity code, telephone number and e-mail address.</p>
		
		<p>The research data includes the collected risk survey data, images of teeth, data from the customer experience survey, findings and conclusions found on the basis of the data, customer satisfaction survey data and dental status data (for example, fillings and extracted teeth, incipient and manifested caries, tooth wear, visible plaque, tartar and gum bleeding by teeth), index data on oral health status (for example, data on the number of teeth and dental surfaces that have cavities, indices describing the amount of plaque, the condition of gums and erosive wear) and the data on the need for treatment and examination intervals from the patient register. Of these, dental status data and dental images may in some cases be unique identifying data if combined with other data (from outside the study registry). </p>
		
		<h2>11. Sources of personal data</h2>
		<p>The data is collected from you in the research situation (survey responses and pictures) and from the patient register of the dental service provider (Kuusamo or other municipality or city, Kallio municipal basic services consortium or another dental foundation, group or company offering dental care, such as the Finnish Student Health Service, Mehiläinen, Terveystalo, Plusterveys, Oral Hammaslääkärit, Coronaria or Pihlajalinna) (information describing the oral and dental health).</p>
		
		<h2>12. Transfer and disclosure of the personal data to third parties</h2>
		<p>In the study, the data collected from you will be disclosed to the Finnish Social and Health Data Permit Authority Findata, so that Findata can combine the collected data with the data extracted from the patient registers according to Section 14 of Act on secondary use of health and social data (552/2019) for the use of the research team in a data-secure operating environment. The presentation of the results of the study may be accompanied by the publication of all or part of the images collected in order to clarify the study and its results.</p>
		
		<h2>13. Transfer or disclosure of personal data to countries outside the European Union/European Economic Area</h2>
		<p>No transfer or disclosure.</p>
		
		<h2>14. Automated decisions</h2>
		<p>No automated decisions. </p>
		
		<p>The study will create and study a system aimed at automatic risk assessment and the making of treatment recommendations, but they will not affect you in any way, as the study that is not directly associated with health care and its data is not communicated to health care.</p>

		<h2>15. Safeguards to protect personal data</h2>
		<input type="checkbox" disabled="disabled" checked="checked" /> The data is confidential.<br />
		Protection of manual material: The paper consent forms are stored in PDF format. <br />
		<br />
		Personal data processed in IT systems:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> username<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> password<br />
		<input type="checkbox" disabled="disabled" /> logging<br />
		<input type="checkbox" disabled="disabled" /> access control<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> other, please specify: encryption <br />
		Processing of direct identifiers:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Direct identifiers will be removed in the analysis phase<br />
		<input type="checkbox" disabled="disabled" /> The material to be analysed includes direct identifiers. Reason: (reason for retention of direct identifiers)<br />
		<br />
		
		<h2>16. Processing of personal data after the completion of the study</h2>
		<input type="checkbox" disabled="disabled" /> The research material will be deleted<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> The research material will be archived:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> without identifiers <input type="checkbox" disabled="disabled"/> with identifiers<br />
		Where will the material be archived and for how long: University of Oulu, estimated archiving period 10 years.<br />
		<br />
		
		<h2>17. Your rights as a data subject, and exceptions to these rights?</h2>
		<p>In accordance with data protection legislation, the subject has certain rights with which the subject can ensure privacy protection, which is a fundamental right. <b>If the subject wishes to exercise the right mentioned in this section 17, contact the study contact person mentioned in section 1. </b></p>
		
		<p><u>Withdrawing consent (GDPR Article 7)</u><br />
		You have the right to withdraw your consent, provided that the processing of the personal data is based on consent. The withdrawal of consent shall not affect the lawfulness of processing based on consent before its withdrawal. </p>
		
		<p><u>Right of access (GDPR Article 15)</u><br />
		You have the right to obtain information on whether or not personal data concerning you are being processed in the project, as well as the data being processed. You can also request a copy of the personal data undergoing processing.</p>

		<p><u>Right to rectification (GDPR Article 16)</u><br />
		If there are inaccuracies or errors in your personal data undergoing processing, you have the right to request their rectification or supplementation.</p>
		
		<p><u>Right to request erasure of data or restriction of processing, right to object to the processing request to transfer data from one system to another (Articles 17, 18, 20, 21 GDPR)</u><br />
		You may also request the erasure or restriction of the processing of your personal data used in the study and you may object to the processing or request the transfer of your data from one system to another.</p>
		<br />
		
		<b><u>Derogating from rights</u></b>
		<p>The rights listed above in section <b>17 Your rights as a data subject, and exceptions to these rights</b> are not absolute and therefore do not apply in every case, and exceptions to these rights may be made under data protection legislation, for example, where these rights impede or severely impair the achievement of a scientific or historical research or statistical purpose. The need for derogations will always be assessed on a case-by-case basis.</p>
		
		<h2>18. Right to lodge a complaint</h2>
		<p>You have the right to lodge a complaint with the Data Protection Ombudsman's Office if you think your personal data has been processed in violation of applicable data protection laws.</p>
		<br />

		Contact information:<br />
		Data Protection Ombudsman's Office<br />
		Address: Ratapihantie 9, 6th floor, 00520 Helsinki<br />
		Postal address: PL 800, 00521 Helsinki<br />
		Tel (switchboard): 029 56 66700<br />
		Fax: 029 56 66735<br />
		E-mail: <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi</a><br />
	</Document>;
}

function docPrivacyStatementFi(props) {
	return <Document header="Tietosuojailmoitus" file="static/doc/privacy-statement-fi.pdf" open={props.open}>
		<h2>Tietoa tutkimukseen osallistuvalle</h2>
		<p>Olet osallistumassa Oulun yliopiston tekemään tutkimukseen. Tässä selosteessa kuvataan, miten henkilötietojasi käsitellään tutkimuksessa. </p>
		
		<p>Tutkimukseen osallistuminen on vapaaehtoista. Sinuun ei kohdistu mitään negatiivista seuraamusta, jos et osallistu tutkimukseen tai jos keskeytät osallistumisesi tutkimukseen. Jos keskeytät osallistumisesi tutkimukseen, ennen keskeytystä kerättyä aineistoa voidaan kuitenkin käyttää tutkimuksessa*. Tämän ilmoituksen kohdassa 17 kerrotaan tarkemmin, mitä oikeuksia sinulla on ja miten voit vaikuttaa tietojesi käsittelyyn. </p>
		
		<p>*Jos keskeytät osallistumisesi tai peruutat suostumuksesi osallistua tutkimukseen, ennen keskeytystä tai suostumuksen peruutusta kerättyä aineistoa voidaan kuitenkin käyttää tutkimuksessa ellet erityisesti pyydä tietojesi poistamista tai käsittelyn rajoittamista kohdassa 17 kuvattujen oikeuksien mukaisesti. </p>
		
		<h2>1. Tutkimuksen rekisterinpitäjä</h2>
		<p>Rekisterinpitäjänä tutkimuksessa on Oulun yliopisto, joka vastaa tutkimuksen yhteydessä tapahtuvien henkilötietojen käsittelyn lainmukaisuudesta. </p>
		
		<p>Osoite: Oulun yliopisto, PL 8000 90014 Oulun yliopisto (Pentti Kaiteran katu 1, Linnanmaa)</p>
		
		Yhteyshenkilö tutkimusta koskevissa asioissa:<br />
		Nimi: Katri Kukkola<br />
		Osoite: Optoelektroniikan ja mittaustekniikan yksikkö, Tieto- ja sähkötekniikan tiedekunta, Oulun<br />
		yliopisto, PL 8000, 90014 Oulun yliopisto (Erkki Koiso-Kanttilankatu 3, Linnamaa)<br />
		Puhelinnumero: 029 4487 694<br />
		Sähköpostiosoite: <a href="mailto:katri.kukkola@oulu.fi">atri.kukkola@oulu.fi</a><br />
		Sähköpostiosoite: <a href="lupaloikata@oulu.fi">lupaloikata@oulu.fi</a><br />
		<br />
		
		<h2>2. Kuvaus tutkimushankkeesta ja henkilötietojen käsittelyn tarkoitus</h2>
		
		<p>Tutkimuksen tavoitteena on mahdollistaa etäpalveluiden hyödyntäminen suun terveydenhoidossa, erityisesti ennaltaehkäisevässä työssä. Tutkimuksessa valmistellaan ja testataan konseptia ja työkalua etäpalveluun. Tutkimuksella selvitetään, onko virtuaalisesti kerättäviin tietoihin ja koneoppimiseen perustuva suun terveydenhuollon etäpalvelu kykenevä ja sovelias työkalu yleisimpinen suusairauksien ja hammashoitopelon riskien tunnistamiseen, räätälöimään omahoidon ohjausta ja antamaan alustavan suosituksen hoitopoluksi ilman asiantuntijaosallistumista. </p>
		
		<p>Suoria henkilötietoja käytetään vain suun terveydentilaa koskevien potilasasiakirjojen poimimiseen potilasrekisteristä (poiminnan ja yhdistämisen tekee tietolupaviranomainen Findata). Tutkimusrekisteriin talletetaan vain tutkimuksen tarkoituksen kannalta välttämättömiä henkilötietoja. Tutkimuksessa suorat henkilötunnisteet poistetaan ennen analysointia, asiakirjoissa ja tutkimustuloksissa Sinuun viitataan vain tunnistekoodilla. Käsiteltäessä kuvista poistetaan taustatiedot ja rajataan siten, että kasvoja ei näy. Näin ollen tuloksia ei pystytä yhdistämään yksittäiseen henkilöön. </p>
		
		<h2>3. Yhteistyöhankkeena tehtävän tutkimuksen osapuolet ja vastuunjako</h2>
		<p>EI yhteistyöhankkeena tehtävä tutkimus.</p>
		
		<h2>4. Tutkimuksen vastuullinen johtaja tai tutkimuksesta vastaava ryhmä</h2>
		Nimi: Marja- Liisa Laitala<br />
		Osoite: Suunterveyden yksikkö, Lääketieteellinen tiedekunta, PL 5000, 90014 Oulun yliopisto (Aapistie 3, Kontinkangas)<br />
		Puhelinnumero: 029 4485 423<br />
		Sähköpostiosoite: <a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
		<br />
		
		<h2>5. Tietosuojavastaavan yhteystiedot</h2>
		<p>Oulun yliopiston tietosuojavastaavan sähköpostiosoite: <a href="mailto:dpo@oulu.fi">dpo@oulu.fi</a>.</p>
		
		<h2>6. Tutkimuksen suorittajat</h2>
		<p>Tutkimuksen suorittavat Oulun yliopiston lääketieteellisen tiedekunnan Suunterveyden yksikön, tieto- ja sähkötekniikan tiedekunnan Optoelektroniikan ja mittaustekniikan sekä Biomimetiikan ja älykkäiden järjestelmien yksiköiden tutkijat ja tutkimusavustajat. </p>
		
		<h2>7. Tutkimuksen nimi, luonne ja tutkimuksen kestoaika</h2>
		Tutkimuksen nimi: Suunterveyden digiloikka -kohti virtuaalista vastaanottoa<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Kertatutkimus<br />
		<input type="checkbox" disabled="disabled" /> Seurantatutkimus<br />
		
		Tutkimuksen kestoaika (kuinka kauan henkilötietoja käsitellään):<br />
		<p>Tutkimuksen arvioidaan kestävän vuoden 2025 loppuun, mutta se voi olla pidempikin, jos tutkimukseen liittyvä julkaisutoiminta tai väitöskirjatyö sitä vaatii. On myös mahdollista, että aineiston pohjalta tehdään saman käyttötarkoituksen mukaista jatkotutkimusta.</p>
		
		<h2>8. Henkilötietojen käsittelyn oikeusperuste</h2>
		Henkilötietoja käsitellään seuraavalla yleisen tietosuoja-asetuksen 6 artiklan 1 kohdan mukaisella perusteella:<br />
		<input type="checkbox" disabled="disabled" /> tutkittavan suostumus<br />
		<input type="checkbox" disabled="disabled" /> rekisterinpitäjän lakisääteisen velvoitteen noudattaminen<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> yleistä etua koskeva tieteellinen tai historiallinen tutkimus, tilastointi tai julkisen vallan käyttö<br />
		<input type="checkbox" disabled="disabled" /> rekisterinpitäjän tai kolmannen osapuolen oikeutettu etu (jos oikeutettu etu niin mikä: )<br />
		<br />
		
		<h2>9. Arkaluonteiset henkilötiedot</h2>
		Tutkimuksessa käsitellään seuraavia arkaluonteisia henkilötietoja:<br />
		<input type="checkbox" disabled="disabled" /> Rotu tai etninen alkuperä<br />
		<input type="checkbox" disabled="disabled" /> Poliittiset mielipiteet<br />
		<input type="checkbox" disabled="disabled" /> Uskonnollinen tai filosofinen vakaumus<br />
		<input type="checkbox" disabled="disabled" /> Ammattiliiton jäsenyys<br />
		<input type="checkbox" disabled="disabled" /> Geneettiset tiedot<br />
		<input type="checkbox" disabled="disabled" /> Biometristen tietojen käsittely henkilön yksiselitteistä tunnistamista varten<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Terveys<br />
		<input type="checkbox" disabled="disabled" /> Luonnollisen henkilön seksuaalinen käyttäytyminen tai suuntautuminen<br />
		<br />
		Arkaluonteisten tietojen käsittely perustuu seuraavaan tietosuoja-asetuksen 9 artiklan 2 kohdan mukaiseen erityisehtoon:<br />
		<input type="checkbox" disabled="disabled" /> Tutkittavan suostumus<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Tieteellinen tai historiallinen tutkimustarkoitus tai tilastollinen tarkoitus<br />
		<input type="checkbox" disabled="disabled" /> Tutkittava on saattanut käsiteltävät arkaluonteiset tiedot julkisiksi<br />
		<input type="checkbox" disabled="disabled" /> Muu peruste (mikä?):<br />
		<input type="checkbox" disabled="disabled" /> Tutkimuksessa käsitellään rikostuomiota tai rikkomuksia koskevia tietoja.<br />
		<br />
		
		<h2>10. Mitä henkilötietoja tutkimusaineisto sisältää</h2>
		<p>Tutkimuksessa kerättäviä yksilöiviä henkilötietoja ovat nimi, henkilötunnus, puhelinnumero ja sähköpostiosoite.</p>
		
		<p>Tutkimusaineiston tiedot sisältävät kerättävät riskikyselyn tiedot, kuvat hampaistosta, asiakaskokemuskyselyn tiedot, niiden perusteella havaitut löydökset ja päätelmät ja tyytyväisyyskyselyn tiedot sekä hampaiston tilaa kuvaavat tiedot (esimerkiksi paikat ja poistetut hampaat, alkavat ja ilmenneet kariesvauriot, hampaiden kulumisvauriot, näkyvä plakki, hammaskivi sekä ienverenvuoto hampaittain), suun terveydentilaa kuvaavat indeksitiedot (esimerkiksi reikiintyneiden hampaiden ja hammaspintojen määrää kuvaavat tiedot, plakin määrää ja ikenien tilaa sekä eroosiivista kulumista kuvaavat indeksit) ja hoidon tarvetta sekä tarkastusväliä koskevia tietoja potilasrekisteristä. Näistä hampaiston tilaa kuvaavat tiedot ja hampaiston kuvat voivat joissain tapauksessa olla yksilöiviä tietoja, jos ne yhdistetään muihin (tutkimusrekisterin ulkopuolisiin) tietoihin. </p>
		
		<h2>11. Mistä lähteistä henkilötietoja kerätään</h2>
		<p>Tietoja kerätään Sinulta tutkimustilanteessa (kyselyvastaukset ja kuvat) ja hammashoidon palveluntarjoajan (Kuusamon tai muun kunnan tai kaupungin, peruspalvelukuntayhtymä Kallion tai muun hammashoitoa tarjoavan säätiön, yhtymän tai yrityksen, kuten YTHS:n, Mehiläisen, Terveystalon, Plusterveyden, Oral Hammaslääkäreiden, Coronarian tai Pihlajalinnan) potilasrekisteristä (suun ja hampaiston tilaa kuvaavat tiedot). </p>
		
		<h2>12. Henkilötietojen siirto tai luovuttaminen tutkimusryhmän ulkopuolelle</h2>
		<p>Tutkimuksessa Sinulta kerättyjä tietoja luovutetaan tietolupaviranomainen Findatalle, jotta se voi yhdistää kerätyt tiedot potilasrekistereitä poimittaviin tietoihin Toisiolainlain sosiaali- ja terveystietojen toissijaisesta käytöstä (<a href="https://www.finlex.fi/fi/laki/alkup/2019/20190552">Toisiolaki</a>) 14§ mukaisesti tutkimusryhmän käytettäväksi tietoturvallisessa käyttöympäristössä. </p>
		
		<h2>13. Henkilötietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</h2>
		<p>Ei siirretä.</p>
		
		<h2>14. Automatisoitu päätöksenteko</h2>
		<p>Automaattisia päätöksiä ei tehdä.</p>
		
		<p>Tutkimuksessa luodaan ja tutkitaan järjestelmää, jolla pyritään automaattiseen riskinarviointiin ja hoitosuositusten tekoon, mutta ne eivät millään tavalla vaikuta Sinuun, koska kyse on tutkimuksesta, mikä ei ole suorassa yhteydessä terveydenhuoltoon ja terveydenhuolto ei saa siitä tietoa.</p>

		<h2>15. Henkilötietojen suojauksen periaatteet</h2>
		<input type="checkbox" disabled="disabled" checked="checked" /> Tiedot ovat salassa pidettäviä.<br />
		Manuaalisen aineiston suojaaminen: Paperiset suostumuslomakkeet tallennetaan pdf muodossa.<br />
		<br />
		Tietojärjestelmissä käsiteltävät tiedot:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> käyttäjätunnus<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> salasana<br />
		<input type="checkbox" disabled="disabled" /> käytön rekisteröinti<br />
		<input type="checkbox" disabled="disabled" /> kulunvalvonta<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> muu, mikä: kryptaus<br />
		Suorien tunnistetietojen käsittely:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Suorat tunnistetiedot poistetaan analysointivaiheessa<br />
		<input type="checkbox" disabled="disabled" /> Aineisto analysoidaan suorin tunnistetiedoin, koska (peruste suorien tunnistetietojen säilyttämiselle):<br />
		<br />
		
		<h2>16. Henkilötietojen käsittely tutkimuksen päättymisen jälkeen</h2>
		<input type="checkbox" disabled="disabled" /> Henkilötietosi hävitetään<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Henkilötietosi arkistoidaan:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> ilman tunnistetietoja <input type="checkbox" disabled="disabled"/> tunnistetiedoin<br />
		Mihin aineisto arkistoidaan ja miten pitkäksi aikaa: Oulun yliopisto, arvioitu arkistointiaika 10 vuotta.<br />
		<br />
		
		<h2>17. Mitä oikeuksia sinulla on ja oikeuksista poikkeaminen?</h2>
		<p>Tietosuojalainsäädännön mukaisesti tutkittavalla on tiettyjä oikeuksia, joilla tutkittava voi varmistaa perusoikeuksiin kuuluvan yksityisyyden suojan toteutumisen. <b>Mikäli tutkittava haluaa käyttää tässä kohdassa 17 mainittua oikeuttaan, ota yhteyttä kohdassa 1 mainittuun tutkimuksen yhteyshenkilöön</b></p>
		
		<p><u>Suostumuksen peruuttaminen (tietosuoja-asetuksen 7 artikla)</u><br />
		Sinulla on oikeus peruuttaa antamasi suostumus, mikäli henkilötietojen käsittely perustuu suostumukseen. Suostumuksen peruuttaminen ei vaikuta suostumuksen perusteella ennen sen peruuttamista suoritetun käsittelyn lainmukaisuuteen. </p>
		
		<p><u>Oikeus pyytää pääsyä tietoihin (tietosuoja-asetuksen 15 artikla)</u><br />
		Sinulla on oikeus pyytää tietoa siitä, käsitelläänkö henkilötietojasi tutkimuksessa ja mitä henkilötietojasi tutkimuksessa käsitellään. Voit myös halutessasi pyytää jäljennöstä käsiteltävistä henkilötiedoista. </p>

		<p><u>Oikeus pyytää tietojen oikaisemista (tietosuoja-asetuksen 16 artikla)</u><br />
		Jos käsiteltävissä henkilötiedoissasi on epätarkkuuksia tai virheitä, sinulla on oikeus pyytää niiden oikaisua tai täydennystä.</p>
		
		<p><u>Oikeus pyytää tietojen poistamista tai käsittelyn rajoittamista, oikeus vastustaa käsittelyä pyytää tietojen siirtoa järjestelmästä toiseen (tietosuoja-asetuksen 17, 18, 20, 21 artiklat)</u><br />
		Voit myös pyytää tutkimuksessa käytettyjen henkilötietojesi poistamista tai niiden käsittelyn rajoittamista sekä voit vastustaa käsittelyä tai pyytää tietojesi siirtämistä järjestelmästä toiseen.</p>
		<br />
		
		<b><u>Oikeuksista poikkeaminen</u></b>
		<p>Edellä tässä kohdassa <b>17 Mitä oikeuksia sinulla on ja oikeuksista poikkeaminen</b> mainitut oikeudet eivät ole ehdottomia eivätkä siten päde jokaisessa tapauksessa ja näistä oikeuksista saatetaan poiketa tietosuojaa koskevan lainsäädännön mukaisesti esim. silloin kuin oikeudet estävät tieteellisen tai historiallisen tutkimustarkoituksen tai tilastollisen tarkoituksen saavuttamisen tai vaikeuttavat sitä suuresti. Tarvetta poiketa oikeuksista arvioidaan aina tapauskohtaisesti.</p>
		
		<h2>18. Valitusoikeus</h2>
		<p>Sinulla on lisäksi oikeus tehdä valitus tietosuojavaltuutetun toimistoon, mikäli katsot, että henkilötietojesi käsittelyssä on rikottu voimassa olevaa tietosuojalainsäädäntöä.</p>
		<br />

		Yhteystiedot:<br />
		Tietosuojavaltuutetun toimisto<br />
		Käyntiosoite: Ratapihantie 9, 6. krs, 00520 Helsinki<br />
		Postiosoite: PL 800, 00521 Helsinki<br />
		Vaihde: 029 56 66700<br />
		Faksi: 029 56 66735<br />
		Sähköposti: <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi</a><br />
	</Document>;
}

function docPrivacyStatementSe(props) {
	return <Document header="Sekretessmeddelande" file="static/doc/privacy-statement-se.pdf" open={props.open}>
		<h2>Anvisningar för den som deltar i undersökningen</h2>
		<p>Du deltar i en undersökning som genomförs av Uleåborgs universitet. I denna redogörelse beskrivs hur dina personuppgifter hanteras i undersökningen. </p>
		
		<p>Det är frivilligt att delta i undersökningen. Det blir inga negativa påföljder för dig om du inte deltar i undersökningen eller om du avbryter deltagandet i undersökningen Om du avbryter deltagandet i undersökningen kan emellertid det material som samlats in före avbrytandet användas i undersökningen*. I punkt 17 i denna redogörelse beskrivs noggrannare vilka dina rättigheter är och hur du kan påverka hanteringen av dina uppgifter.</p>
		
		<p>Om du avbryter deltagandet eller återkallar ditt samtycke att delta i undersökningen kan information som samlats in före avbrytandet eller återkallandet av samtycket användas i undersökningen, om du inte enkom ber om att dina uppgifter avlägsnas eller att hanteringen av dem begränsas, i enlighet med de rättigheter som beskrivs i punkt 17.</p>
		
		<h2>1. Personuppgiftsansvarig för undersökningen</h2>
		<p>Personuppgiftsansvarig för undersökningen är Uleåborgs universitet, som ansvarar för att hanteringen av personuppgifter som sker under undersökningen är lagenlig.</p>
		
		<p>Adress: University of Oulu, P.O. Box 8000, FI-90014 University of Oulu (Pentti Kaiteran katu 1, Linnanmaa)</p>
		
		Kontaktperson i ärenden som rör undersökningen:<br />
		Namn: Katri Kukkola<br />
		Adress: Optoelectronics and Measurement Techniques research unit, Faculty of Information Technology and Electrical Engineering, University of Oulu, P.O. Box 8000, FI-90014 University of Oulu (Erkki Koiso-Kanttilankatu 3, Linnamaa)<br />
		Telefonnummer: +358 29 4487 694<br />
		E-postadress: <a href="mailto:katri.kukkola@oulu.fi">atri.kukkola@oulu.fi</a><br />
		E-postadress: <a href="lupaloikata@oulu.fi">lupaloikata@oulu.fi</a><br />
		<br />
		
		<h2>2. Beskrivning av forskningsprojektet och syftet med hanteringen av personuppgifter</h2>
		
		<p>Målet med undersökningen är att möjliggöra utnyttjande av digitala tjänster inom munhälsovården, särskilt inom det förebyggande arbetet. Inom undersökningen förbereds och testas ett koncept och verktyg för digitala tjänster. Med undersökningen utreds om digitala tjänster inom munhälsovården, som bygger på uppgifter som samlas in virtuellt och maskininlärning, är ett kapabelt och ändamålsenligt verktyg för att identifiera riskerna för de vanligaste munsjukdomarna och tandvårdsrädsla, skräddarsy anvisningar för egenvård och ge en preliminär rekommendation om vård utan att en expert är närvarande. </p>
		
		<p>Syftet med hanteringen av personuppgifterna är att utveckla riskbedömningen och förebyggandet av munsjukdomar. Observationerna och slutsatserna av enkätsvaren och bilderna på tänderna jämförs med uppgifterna om mun- och tandhälsan som finns i patientregistret (uppgifter om kontroller av munhälsan), med statistiska metoder och maskininlärning. Med hjälp av uppgifterna som samlas in i undersökningen görs ett system som bygger på maskininlärning (som utnyttjar artificiell intelligens). Med en separat enkät utreds hur lämpliga och populära de virtuella tjänsterna är.</p>
		
		<p>Direkta personuppgifter används endast för att samla in journalhandlingar om munnens hälsotillstånd från patientregistret. (Tillståndsmyndigheten Findata samlar in och förenar personuppgifterna.) I undersökningsregistret sparas endast personuppgifter som är nödvändiga för undersökningen. I undersökningen raderas direkta personbeteckningar före analyseringen. I dokumenten och undersökningsresultaten hänvisar man till dig endast med en identitetskod. När bilderna hanteras raderas bakgrundsuppgifterna och bilderna beskärs så att ansiktet inte syns. Således kan resultaten inte kopplas till en enskild person.  </p>
		
		<h2>3. Parterna och ansvarsfördelningen i undersökningen som görs som ett samarbetsprojekt</h2>
		<p>Undersökning som INTE görs som ett samarbetsprojekt.</p>
		
		<h2>4. National Coordinator or responsible research group</h2>
		Namn: Marja- Liisa Laitala<br />
		Adress: Unit of Oral Health Sciences, Faculty of Medicine, P.o. Box 5000, 90014 University of Oulu (Aapistie 3, Kontinkangas)<br />
		Telefonnummer: 029 4485 423<br />
		E-postadress: <a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
		<br />
		
		<h2>5. Dataskyddsombudets kontaktuppgifter</h2>
		<p>Uleåborgs universitets dataskyddsombuds e-postadress: <a href="mailto:dpo@oulu.fi">dpo@oulu.fi</a>.</p>
		
		<h2>6. Undersökningens aktörer</h2>
		<p>Undersökningen genomförs av forskare och forskningsbiträden vid enheten Oral Health Sciences vid Uleåbors universitets medicinska fakultet samt Optoelectronics and Measurement Techniques research enheten och Biomimetics and Intelligent Systems Group vid fakulteten för informationsteknik och elektroteknik of the Faculty of Information Technology and Electrical Engineering, and the Biomimetics and Intelligent Systems Group.</p>
		
		<h2>7. Undersökningens namn, karaktär och längd</h2>
		Undersökningens namn: Munhälsans digitala språng – mot en virtuell mottagning<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Engångsstudie<br />
		<input type="checkbox" disabled="disabled" /> Uppföljningsstudie<br />
		
		Undersökningens längd (hur länge personuppgifter hanteras):<br />
		<p>Man uppskattar att undersökningen pågår till slutet av 2025, men den kan pågå också längre om publiceringsverksamheten som rör undersökningen eller avhandlingsarbetet kräver det. Det är också möjligt att man utifrån materialet gör en fortsatt undersökning för samma användningsändamål.</p>
		
		<h2>8. Rättslig grund för hantering av personuppgifterna</h2>
		Personuppgifterna behandlas utifrån artikel 6, punkt 1 i den allmänna dataskyddsförordningen under förutsättning att: <br />
		<input type="checkbox" disabled="disabled" /> Undersökningsdeltagaren har lämnat sitt samtycke<br />
		<input type="checkbox" disabled="disabled" /> Den personuppgiftsansvarigas lagenliga förpliktelse följs<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Vetenskaplig eller historisk undersökning, statistikföring eller offentlig maktutövning som rör det allmänna intresset<br />
		<input type="checkbox" disabled="disabled" /> Den personuppgiftsansvarigas eller en tredje parts berättigade intresse (om berättigat intresse, vilket:)<br />
		<br />
		
		<h2>9. Känsliga personuppgifter</h2>
		I undersökningen hanteras följande känsliga personuppgifter:<br />
		<input type="checkbox" disabled="disabled" /> Ras eller etniskt ursprung<br />
		<input type="checkbox" disabled="disabled" /> Politiska åsikter<br />
		<input type="checkbox" disabled="disabled" /> Religiös eller filosofisk övertygelse<br />
		<input type="checkbox" disabled="disabled" /> Medlemskap i fackförbund<br />
		<input type="checkbox" disabled="disabled" /> Genetiska uppgifter<br />
		<input type="checkbox" disabled="disabled" /> Behandling av biometriska uppgifter för entydig identifiering<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Hälsa<br />
		<input type="checkbox" disabled="disabled" /> Den fysiska personens sexualliv eller sexuella läggning<br />
		<br />
		Behandlingen av känsliga uppgifter bygger på följande specialvillkor i artikel 9 punkt 2 i dataskyddsförordningen:<br />
		<input type="checkbox" disabled="disabled" /> Undersökningsdeltagarens samtycke<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Vetenskapliga eller historiska forskningsändamål eller statistiska ändamål<br />
		<input type="checkbox" disabled="disabled" /> Undersökningsdeltagaren har offentliggjort känsliga uppgifter som behandlas<br />
		<input type="checkbox" disabled="disabled" /> Annan motivering (vilken?):<br />
		<input type="checkbox" disabled="disabled" /> I undersökningen behandlas uppgifter om fällande dom eller förseelse.<br />
		<br />
		
		<h2>10. Vilka personuppgifter innehåller forskningsmaterialet?</h2>
		<p>De särskiljande personuppgifter som samlas in i undersökningen är namn, personbeteckning, telefonnummer och e-postadress.</p>
		
		<p>Undersökningsmaterialet innehåller uppgifter från riskenkäten, bilder av tänderna, uppgifter från klientupplevelseenkäten, fynd och slutsatser som observerats utifrån dem, och uppgifter från klientnöjdhetsenkäten. Dessutom innehåller det uppgifter som beskriver tändernas tillstånd (till exempel lagningar och utdragna tänder, begynnande och redan uppstådda kariesskador, nötningsskador, synligt plack, tandsten samt blödande tandkött per tand), indexuppgifter som beskriver hälsotillståndet i munnen (till exempel uppgifter om tänder med hål och antalet tandytor, index som beskriver mängden plack och tandköttets läge samt erosiv nötning) och uppgifter från patientregistret om vårdbehovet och kontrollintervallen. Dessa uppgifter som beskriver tändernas tillstånd och bilder på tänderna kan i vissa fall vara särskiljande uppgifter om de förenas med andra uppgifter (som inte finns i undersökningsregistret).</p>
		
		<h2>11. Från vilka källor samlas personuppgifter in?</h2>
		<p>The data is collected from you in the research situation (survey responses and pictures) and from the patient register of the dental service provider (Kuusamo or other municipality or city, Kallio municipal basic services consortium or another dental foundation, group or company offering dental care, such as the Finnish Student Health Service, Mehiläinen, Terveystalo, Plusterveys, Oral Hammaslääkärit, Coronaria or Pihlajalinna) (information describing the oral and dental health).</p>
		
		<h2>12. Överföring eller överlämnande av personuppgifter utanför forskningsgruppen</h2>
		<p>Uppgifter som samlas in av dig under undersökningen överlåts till tillståndsmyndigheten Findata, så att Findata kan förena de insamlade uppgifterna med de uppgifter som samlas in från patientregistren i enlighet med 14 § i Lagen om sekundär användning av personuppgifter inom social- och hälsovården, så att forskningsgruppen kan använda dem i en informationssäker användningsmiljö. I samband med presentationen av resultaten kan man publicera bilder eller delar av dem som samlats in under undersökningen för att förtydliga undersökningen eller resultaten.</p>
		
		<h2>13. Överföring av personuppgifter utanför EU eller EES</h2>
		<p>Överförs inte.</p>
		
		<h2>14. Automatiserat beslutsfattande</h2>
		<p>Inga automatiska beslut fattas.</p>
		
		<p>Inom undersökningen skapas och undersöks ett system med vilket man eftersträvar automatisk riskbedömning och att göra vårdrekommendationer, men de påverkar på inget sätt dig, eftersom det är fråga om en undersökning som inte har någon direkt förbindelse till hälso- och sjukvården, och hälso- och sjukvården får ingen information om den.</p>

		<h2>15. Principer för skydd av personuppgifter</h2>
		<input type="checkbox" disabled="disabled" checked="checked" /> Uppgifterna är sekretessbelagda.<br />
		Skydd av det manuella materialet: Pappersblanketterna för samtycke sparas i pdf-format.<br />
		<br />
		Uppgifter som behandlas i datasystem:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> användarnamn<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> lösenord<br />
		<input type="checkbox" disabled="disabled" /> registrering av användning<br />
		<input type="checkbox" disabled="disabled" /> passerkontroll<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> annat, vad: kryptering<br />
		Behandling av direkta identifikationsuppgifter:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> De direkta identifikationsuppgifterna raderas i analyseringsskedet.<br />
		<input type="checkbox" disabled="disabled" /> Materialet analyseras med direkta identifikationsuppgifter, eftersom (motivering till att spara direkta identifikationsuppgifter):<br />
		<br />
		
		<h2>16. Hantering av personuppgifterna efter att undersökningen avslutats</h2>
		<input type="checkbox" disabled="disabled" /> Dina personuppgifter raderas<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> Dina personuppgifter arkiveras:<br />
		<input type="checkbox" disabled="disabled" checked="checked" /> utan identifikationsuppgifter <input type="checkbox" disabled="disabled"/> med identifikationsuppgifter<br />
		Var arkiveras materialet och hur länge: Uleåborgs universitet, uppskattad arkiveringstid 10 år.<br />
		<br />
		
		<h2>17. Vilka rättigheter har du och avvikelser från rättigheterna?</h2>
		<p>I enlighet med datasekretesslagstiftningen har undersökningsdeltagaren vissa rättigheter, med vilka den undersökta kan säkerställa att intimitetsskyddet som hör till de grundläggande rättigheterna verkställs. <b>Om undersökningsdeltagaren vill använda sin rätt som nämns här i punkt 17, kontakta forskningens kontaktperson i punkt 1.</b></p>
		
		<p><u>Återkallande av samtycke (artikel 7 i dataskyddsförordningen)</u><br />
		Du har rätt att återkalla ditt samtycke om behandlingen av personuppgifterna grundar sig på samtycke. Återkallandet av samtycket påverkar inte lagenligheten hos behandlingen av personuppgifter som gjordes före återkallandet.</p>
		
		<p><u>Rätt att be om att få tillgång till personuppgifter (artikel 15 i dataskyddsförordningen)</u><br />
		Du har rätt att be om att få veta om dina personuppgifter behandlas i undersökningen och vilka av dina personuppgifter som behandlas i undersökningen. Om du vill kan du också be om att få en kopia på de personuppgifter som behandlas.</p>

		<p><u>Rätt att be om rättelse av personuppgifter (artikel 16 i dataskyddsförordningen)</u><br />
		IOm det i de behandlade personuppgifterna finns inexaktheter eller fel har du rätt att be om att de rättas eller kompletteras.</p>
		
		<p><u>Rätt att be om att personuppgifter avlägsnas eller att behandlingen av dem avgränsas, rätt att motsätta sig behandlingen av dem och be om att uppgifterna förflyttas från ett system till ett annat (artiklarna 17, 18, 20, 21 i dataskyddsförordningen)</u><br />
		Du kan också be om att personuppgifterna som används i undersökningen avlägsnas, eller att behandlingen av dem avgränsas, och du kan motsätta dig behandlingen av dem, eller be om att uppgifterna förflyttas från ett system till ett annat.</p>
		<br />
		
		<b><u>Avvikelser från rättigheterna</u></b>
		<p>Rättigheterna som nämnts tidigare i punkt 17 Vilka rättigheter du har och avvikelser från rättigheterna är inte ovillkorliga, och gäller därför inte alltid. Det kan hända att man avviker från dessa rättigheter i enlighet med lagstiftningen som rör dataskydd, till exempel då rättigheterna hindrar uppnående av vetenskapliga eller historiska forskningsändamål eller ett statistiskt ändamål, eller märkbart försvårar dem. Behovet av att avvika från rättigheterna bedöms alltid fall för fall.</p>
		
		<h2>18. Rätt att överklaga</h2>
		<p>Du har dessutom rätt att överklaga till dataombudsmannens byrå om du anser att man vid behandlingen av dina personuppgifter har brutit mot den gällande datasekretesslagstiftningen.</p>
		<br />

		Kontaktuppgifter:<br />
		Dataombudsmannens byrå<br />
		Besöksadress: Bangårdsvägen 9, vån. 6, 00520 Helsingfors<br />
		Postadress: PB 800, 00521 Helsingfors<br />
		Växel: 029 56 66700<br />
		Fax: 029 56 66735<br />
		E-post: <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi</a><br />
	</Document>;
}

function docResearchInfoEn(props) {
	return <Document header="Research notice" file="static/doc/research-info-en.pdf" open={props.open}>
		<h2>Research notice</h2>
		
		<b>Name of the study</b><br />
		<p>Digital Leap in Oral Health – Towards a Virtual Reception</p>
		
		<b>Request to participate in a study</b><br />
		<p>You are being asked to participate in a study to prepare and test a concept and tool for a remote service to assess the risk of the most common oral diseases and dental phobia, which can be used to guide self-care and provide a preliminary treatment recommendation. All persons who are of age are eligible for this study. </p>
		
		<b>Voluntary participation</b><br />
		<p> This notice describes the study and your possible part in it. Please read this notice. If you have any questions, please contact the researchers either by e-mail at <a href="mailto:lupaloikata@oulu.fi">lupaloikata@oulu.fi</a> or by using the contact information at the end of this notice. If you agree to participate in the study, you will be asked to identify yourself via the suomi.fi service and indicate your consent.</p>
		
		<p>Participation in the study is voluntary. You may refuse to take part in the study, withdraw from it or withdraw your consent without any justification at any point of the study. Your right to receive the treatment you need will not be affected by participation, refusal to participate or withdrawal of consent to participate. If you wish to withdraw from the study or withdraw your consent, please contact the researcher. </p>
		
		<b>Organiser of the study</b><br />
		<p>The study has been organised and commissioned by the University of Oulu. The National Coordinator is Professor Marja-Liisa Laitala from the Unit of Oral Health Sciences of the Faculty of Medicine. </p>
		
		<p>The contact person in matters concerning the study is Katri Kukkola, Project Manager.</p>
		
		<b>Purpose of the study</b><br />
		<p>The purpose of this study is to develop a virtual oral health service and its automation to promote oral health, in particular risk assessment, self-care guidance and recommendation of a preliminary treatment path. The aim is also to examine the suitability and desirability of virtual services.  The information obtained from this study can be used in the development of oral disease observation, diagnostics and prevention. Data analytics, computer vision and machine learning (artificial intelligence) are used and developed in the development of automatic detection and identification.</p>
		
		<p>All adult volunteers can take part in the study virtually, in which case the study may have potentially thousands of participants. In addition, approximately 380 young people aged 13–15 from Kuusamo and Ylivieska will be invited to participate in the study. </p>
		
		<b>Progress of the study</b><br />
		<p>Participation in the study consists of at least one virtual examination visit and voluntary adherence to the self-care instructions and exercises given based on the results of the related oral health questionnaire, as well as responding to a customer survey.</p>
		
		<p>Participation in the study will take approximately 30–45 minutes. During this time, you will register for the study and create a user account, answer a questionnaire about oral health behaviour, take pictures of your teeth with a smartphone camera as instructed, and attach the pictures of your choice in the study application, receive feedback produced by the device on the survey, and receive links to self-care instructions and/or exercises and the customer survey for you to complete. </p>
		
		<p>By logging in to the user account, you can see the feedback and the self-care instructions and/or exercises given to you also later during the study. You can also take the survey again to get new feedback, or add photos.</p>
		
		<p>The data collected from you in the study and the observations made from them will be combined and compared with oral and dental health data from the patient register of your dental care provider (e.g. public health care, Finnish Student Health Service, Mehiläinen, Terveystalo, Plusterveys, Oral Hammaslääkärit, Coronaria, Pihlajalinna) by statistical methods and machine learning.</p>
		
		<p>The regional Ethics Committee of the Northern Ostrobothnia Hospital District has assessed the research plan and issued a favourable opinion on it. The Northern Ostrobothnia Hospital District has authorised the study. In addition, Fimea has been notified of the study and a research permit will be applied for from Findata to obtain patient records. </p>
		
		<b>Possible benefits and risks of the study</b><br />
		<p>It is possible that you will not benefit from taking part in this study. You will receive feedback from the device on the survey and self-care instructions and/or exercises. However, the information produced by the study may help to develop remote oral health services and prevention of oral diseases. </p>
		
		<p>There are no adverse effects associated with the study apart from the time spent.</p>
		
		<b>Processing of personal data and confidentiality of data </b><br />
		<p>Personal data is processed for scientific research purposes. The data and research results collected are processed confidentially as required by legislation. All parties and persons processing the data are obligated to keep it confidential. The presentation of the results of the study may be accompanied by the publication of all or part of the images collected in order to clarify the study and its results. A description of the processing of personal data in the study can be found in the study's Privacy Notice.</p>
		
		<b>Costs of the study and compensation for the subject </b><br />
		<p>The study is free of charge and no compensation is paid for participation.</p>
		
		<b>Funding of the study and the researchers' commitments</b><br />
		<p>The study is funded by the University of Oulu and the Council of Oulu Region with the European Union's ERDF project funding. The ERDF project is co-financed by DentView Oy, Tuudo Oy, Tecinspire Oy, Mehiläinen Oy, Local Tapiola Services Ltd and Colgate-Palmolive AB. The principal investigator and other staff are not paid separate remuneration for carrying out the study. The investigators do not have any commitments.</p>
		
		<b>Insurance cover of the study subjects</b><br />
		<p>The subjects of this study are insured under the University of Oulu's patient insurance.</p>
		
		<p>If a procedure performed for the purpose of the study causes you a personal injury, you can claim compensation. In the case of personal injuries, compensation is claimed from the research centre's patient insurance. It compensates for personal injuries caused in connection with health care procedures under the preconditions specified in the Patient insurance act. The Patient Insurance Centre handles the compensation processing of patient injuries.</p>
		
		<b>Dissemination of the research results</b><br />
		<p>This is a scientific study, the results of which may take several years to complete. The research results will be published in relevant national or international scientific publications.</p>
		
		<p>The research results will not be communicated to you separately after the study.</p>
		
		<p>If, by chance, abnormal findings significant for your health are found in the results, the study's dentist will assess their significance for your health and, if necessary, refer you to an appropriate place for further treatment.</p>
		
		<b>End of the study</b><br />
		<p>After the end of the study, your treatment will continue as usual.</p>
		
		<b>More information</b><br />
		<p>If you have any questions related to the study, you can contact Marja-Liisa Laitala or Katri Kukkola, or e-mail <a href="mailto:lupaloikata@oulu.fi">lupaloikata@oulu.fi</a>.</p>
		
		<b>Researchers' contact details </b><br />
		<b>National Coordinator</b><br />
		Professor (cariology, endodontics and paediatric dentistry)<br />
		Marja-Liisa Laitala<br />
		Unit of Oral Health Sciences<br />
		+358 29 448 5423 / +358 40 678 6785<br />
		<a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
		<br />
		<b>Principal investigator</b><br />
		Postdoctoral research fellow  <br />
		Saujanya Karki<br />
		Unit of Oral Health Sciences<br />
		+358 29 448 5643/ +358 50 473 2337<br />
		<a href="mailto:saujanya.karki@oulu.fi">saujanya.karki@oulu.fi</a><br />
		<br />
		<b>Contact person</b><br />
		Project Manager  <br />
		Katri Kukkola<br />
		Optoelectronics and Measurement Techniques research unit<br />
		+358 29 448 7694/ +358 50 350 4872<br />
		<a href="mailto:katri.kukkola@oulu.fi">katri.kukkola@oulu.fi</a><br />
	</Document>;
}

function docResearchInfoFi(props) {
	return <Document header="Tutkimustiedote" file="static/doc/research-info-fi.pdf" open={props.open}>
		<h2>Tiedote tutkimuksesta</h2>
		
		<b>Tutkimuksen nimi</b><br />
		<p>Suunterveyden digiloikka – kohti virtuaalista vastaanottoa</p>
		
		<b>Pyyntö osallistua tutkimukseen</b><br />
		<p>Sinua pyydetään mukaan tutkimukseen, missä valmistellaan ja testataan konseptia ja työkalua etäpalveluun, jolla voidaan arvioida yleisimpien suusairauksien ja hammashoitopelon riskiä ja jota voidaan käyttää omahoidon ohjaukseen ja alustavan hoitosuosituksen antamiseen. Kaikki täysi-ikäiset soveltuvat tähän tutkimukseen. </p>
		
		<b>Osallistumisen vapaaehtoisuus</b><br />
		<p> Tämä tiedote kuvaa tutkimusta ja mahdollista osuuttasi siinä. Perehdy tähän tiedotteeseen. Jos haluat esittää kysymyksiä, ota yhteyttä tutkijoihin joko sähköpostilla <a href="mailto:lupaloikata@oulu.fi">lupaloikata@oulu.fi</a> tai käyttäen tämän tiedotteen lopussa olevia yhteystietoja. Jos suostut osallistumaan tutkimukseen, Sinua pyydetään tunnistautumaan suomi.fi palvelun kautta ja merkitsemään suostumus.  </p>
		
		<p>Tähän tutkimukseen osallistuminen on vapaaehtoista. Voit kieltäytyä osallistumasta tutkimukseen, keskeyttää osallistumisesi tai voit peruuttaa suostumuksesi syytä ilmoittamatta milloin tahansa tutkimuksen aikana. Tutkimukseen osallistuminen, siitä kieltäytyminen tai sen keskeyttäminen eivät vaikuta oikeuteesi saada tarvitsemaasi hoitoa. Jos haluat keskeyttää tutkimukseen osallistumisen tai peruuttaa suostumuksen, ota yhteys tutkijaan. </p>
		
		<b>Tutkimuksen toteuttaja</b><br />
		<p> Tutkimuksen toteuttajana ja toimeksiantajana on Oulun yliopisto. Tutkimuksesta vastaava henkilö on professori Marja-Liisa Laitala Lääketieteellisen tiedekunnan Suunterveyden yksiköstä.  </p>
		
		<p>Tutkimuksen yhteyshenkilönä toimii projektikoordinaattori Katri Kukkola.</p>
		
		<b>Tutkimuksen tarkoitus</b><br />
		<p>Tämän tutkimuksen tarkoituksena on kehittää suunterveyden virtuaalipalvelua ja sen automatisointia suun terveyden edistämiseen, erityisesti riskinarviointiin, omahoidon ohjaukseen ja alustavan hoitopolun suosittelemiseen. Tavoitteena on selvittää myös virtuaalisten palveluiden soveltuvuutta ja haluttavuutta. Tutkimuksesta saatavaa tietoa voidaan hyödyntää suusairauksien havainnoinnin, diagnostiikan ja ennaltaehkäisyn 2 kehittämisessä. Automaattisen havainnoinnin ja tunnistamisen kehittämisessä käytetään ja kehitetään data-analytiikkaa, konenäköä ja koneoppimista (tekoälyä). </p>
		
		<p> Tutkimukseen voivat osallistua virtuaalisesti kaikki täysi-ikäiset vapaaehtoiset tutkittavat, jolloin osallistujia voi olla jopa tuhansia. Sen lisäksi tutkimukseen kutsutaan noin 380 13 -15 vuotiasta nuorta Kuusamosta ja Ylivieskasta.  </p>
		
		<b>Tutkimuksen kulku</b><br />
		<p> Tutkimukseen osallistuminen koostuu vähintään yhdestä virtuaalisesta tutkimuskäynnistä ja siihen liittyvän suunterveyskyselyn tulosten perusteella annettujen omahoito-ohjeiden ja -harjoitusten vapaaehtoisesta noudattamisesta sekä asiakaskyselyyn vastaamisesta. </p>
		
		<p> Tutkimukseen osallistuminen vie arviolta aikaa 30-45 minuuttia. Tänä aikana rekisteröidyt tutkimukseen ja luot käyttäjätilin, vastaat suun terveyskäyttäytymistä kartoittavaan kyselyyn, kuvaat hampaasi mobiilikameralla ohjeiden mukaan, ja liität valitsemasi kuvat tutkimukseen tutkimuksen sovelluksessa, saat laitteen tuottaman palautteen kyselystä ja linkit omahoidon ohjeisiin ja/tai harjoituksiin ja asiakaskyselyn täytettäväksesi. </p>
		
		<p> Käyttäjätilille kirjautumalla voit nähdä palautteen ja Sinulle annetut omahoidon ohjeet ja/tai harjoitteet myöhemminkin tutkimuksen aikana. Voit myös vastata kyselyyn uudestaan, jolloin saat myös uuden palautteen, tai lisätä kuvia. </p>
		
		<p>Tutkimuksessa Sinulta kerättyjä tietoja ja niistä tehtyjä havaintoja yhdistetään ja verrataan tilastollisin menetelmin ja koneoppimisella Sinun hammashoidon palveluntarjoajan potilasrekisteristä (esim. julkinen terveydenhuolto, YTHS, Mehiläinen, Terveystalo, Plusterveys, Oral Hammaslääkärit, Coronaria, Pihlajalinna) saataviin suun ja hampaiden terveyttä koskevin tietoihin. </p>
		
		<p> Pohjois-Pohjanmaan sairaanhoitopiirin alueellinen eettinen toimikunta on arvioinut tutkimussuunnitelman ja antanut siitä puoltavan lausunnon. Pohjois-Pohjanmaan sairaanhoitopiiri on antanut luvan tutkimukselle. Lisäksi tutkimuksesta on tehty ilmoitus Fimealle ja potilasrekisteritietojen saamiseksi haetaan tutkimuslupa Findatalta. </p>
		
		<b>Tutkimuksen mahdolliset hyödyt ja haitat</b><br />
		<p>On mahdollista, että tähän tutkimukseen osallistumisesta ei ole Sinulle hyötyä. Saat laitteen antaman palautteen kyselystä ja omahoito-ohjeita ja/tai -harjoituksia. Tutkimuksen tuottama tieto saattaa kuitenkin auttaa kehittämään suunterveyden etäpalveluita ja suusairauksien ennaltaehkäisyä.</p>
		
		<p>Tutkimukseen ei liity haittoja siihen kuluvaa aikaa lukuun ottamatta.</p>
		
		<b>Henkilötietojen käsittely ja tietojen luottamuksellisuus</b><br />
		<p>Henkilötietoja käsitellään tieteellistä tutkimustarkoitusta varten. Kerättyjä tietoja ja tutkimustuloksia käsitellään luottamuksellisesti lainsäädännön edellyttämällä tavalla. Kaikki tietoja käsittelevät tahot ja henkilöt ovat salassapitovelvollisia. Tutkimuksen tulosten esittämisen yhteydessä voidaan julkaista tutkimuksessa kerättyjä kuvia tai osia 3 niistä tutkimuksen ja sen tulosten selventämisen vuoksi. Kuvaus tutkimuksessa tapahtuvasta henkilötietojen käsittelystä on tutkimuksen tietosuojailmoituksessa.</p>
		
		<b>Tutkimuksen kustannukset ja korvaukset tutkittavalle</b><br />
		<p>Tutkimus on maksuton eikä osallistumisesta makseta korvausta.</p>
		
		<b>Tutkimuksen rahoitus ja tutkijoiden sidonnaisuudet</b><br />
		<p> Tutkimuksen rahoituksesta vastaa Oulun yliopisto ja Pohjois-Pohjanmaan liitto Euroopan unionin EAKR-hankerahoituksella. EAKR hankkeen osarahoittajina ovat Dentview Oy, Tuudo Oy, Tecinspire Oy, Mehiläinen Oy, LähiTapiola Palvelut Oy ja Colgate-Palmolive AB. Tutkijalääkärille ja muulle henkilökunnalle ei makseta erillistä korvausta tutkimuksen tekemisestä. Tutkijoilla ei ole sidonnaisuuksia. </p>
		
		<b>Tutkittavien vakuutusturva</b><br />
		<p>Tässä tutkimuksessa tutkittavat ovat vakuutettuja Oulun yliopiston potilasvakuutuksella.</p>
		
		<p>Jos tutkimuksen takia tehdystä toimenpiteestä Sinulle aiheutuu henkilövahinko, voitte hakea korvausta. Henkilövahingoista haetaan korvausta tutkimuskeskuksen potilasvakuutuksesta. Se korvaa potilasvakuutuslain mukaisesti terveyden‐ ja sairaanhoidon yhteydessä aiheutuneita henkilövahinkoja laissa tarkemmin säädellyin edellytyksin. Potilasvakuutuskeskus huolehtii potilasvahinkojen korvauskäsittelystä.</p>
		
		<b>Tutkimustuloksista tiedottaminen</b><br />
		<p>Kyseessä on tieteellinen tutkimus, jonka tulosten valmistumisessa voi mennä useita vuosia. Tutkimustulokset julkaistaan asianmukaisissa kansallisissa tai kansainvälisissä tieteellisissä julkaisuissa.</p>
		
		<p>Tutkimuksen tuloksista ei tiedoteta Sinua erikseen tutkimuksen jälkeen.</p>
		
		<p>Mikäli tutkimustuloksissa havaitaan sattumalta poikkeavia, terveytesi kannalta merkittäviä löydöksiä, tutkimuksen hammaslääkäri arvioi niiden merkityksen terveytesi kannalta ja ohjaa tarvittaessa Sinut asianmukaiseen jatkohoitopaikkaan. </p>
		
		<b>Tutkimuksen päättyminen</b><br />
		<p>Tutkimuksen  päättymisen  jälkeen  hoitosi  jatkuu  tavanomaisen  hoitokäytännön mukaisesti. </p>
		
		<b>Lisätiedot</b><br />
		<p>Jos Sinulla on tutkimukseen liittyviä kysymyksiä, voitte kysyä niitä Marja Liisa Laitalalta tai Katri Kukkolalta tai sähköpostilla myös osoitteesta <a href="mailto:lupaloikata@oulu.fi">lupaloikata@oulu.fi</a>.</p>
		
		<b>Tutkijoiden yhteystiedot</b><br />
		<b>Tutkimuksesta vastaava henkilö</b><br />
		Professori (kariologia, juurenhoito-oppi ja lasten hammashoito)<br />
		Marja-Liisa Laitala<br />
		Suunterveyden yksikkö<br />
		+358 29 448 5423 / +358 40 678 6785<br />
		<a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
		<br />
		<b>Päätutkija</b><br />
		Tutkijatohtori<br />
		Saujanya Karki<br />
		Suunterveyden yksikkö<br />
		+358 29 448 5643/ +358 50 473 2337<br />
		<a href="mailto:saujanya.karki@oulu.fi">saujanya.karki@oulu.fi</a><br />
		<br />
		<b>Yhteyshenkilö</b><br />
		Projektikoordinaattori<br />
		Katri Kukkola<br />
		Optoelektroniikan ja mittaustekniikan yksikkö<br />
		Puh.+358 29 448 7694/ +358 50 350 4872<br />
		<a href="mailto:katri.kukkola@oulu.fi">katri.kukkola@oulu.fi</a><br />
	</Document>;
}

function docResearchInfoSe(props) {
	return <Document header="Undersökningsmeddelande" file="static/doc/research-info-se.pdf" open={props.open}>
		<h2>Undersökningsmeddelande</h2>
		
		<b>Undersökningens namn</b><br />
		<p>Munhälsans digitala språng – mot en virtuell mottagning</p>
		
		<b>Förfrågan om att delta i undersökningen</b><br />
		<p>Vi ber dig delta i en undersökning där ett koncept och verktyg för en digital tjänst förbereds och testas. Avsikten med tjänsten är att bedöma risken för de vanligaste munsjukdomarna och tandvårdsrädsla samt att tjänsten ska kunna användas för handledning av egenvård och för att ge en preliminär vårdrekommendation. Alla myndiga personer kan delta i undersökningen. </p>
		
		<b>Det är frivilligt att delta</b><br />
		<p>I detta meddelande beskrivs undersökningen och din eventuella insats. Läs noga igenom meddelandet. Om du har frågor kan du kontakta forskarna antingen per e-post <a href="mailto:lupaloikata@oulu.fi">lupaloikata@oulu.fi</a> eller genom att använda kontaktuppgifterna i slutet av meddelandet. Om du deltar i undersökningen ber vi dig att identifiera dig via tjänsten suomi.fi, och att ge ditt samtycke.</p>
		
		<p>Det är frivilligt att delta i undersökningen. Du behöver inte delta i undersökningen, du kan avbryta deltagandet eller återkalla ditt samtycke utan att ange orsak när som helst under undersökningen. Det påverkar inte din rätt att få den vård du behöver oavsett om du deltar eller inte deltar eller avbryter deltagandet i undersökningen. Om du vill avbryta deltagandet i undersökningen eller återkalla ditt samtycke ska du kontakta forskaren.</p>
		
		<b>Vem genomför undersökningen?</b><br />
		<p>Undersökningen genomförs av och dess uppdragsgivare är Uleåborgs universitet. Professor Marja-Liisa Laitala från Munhälsans enhet vid medicinska fakulteten svarar för undersökningen.</p>
		
		<p>Projektkoordinator Katri Kukkola är kontaktperson.</p>
		
		<b>Undersökningens syfte</b><br />
		<p>Syftet med undersökningen är att utveckla och automatisera en virtuell tjänst för munhälsa för att främja munhälsan, särskilt för riskbedömning, anvisningar för egenvård och rekommendationer för preliminär vård. Målet är också att utreda om det finns intresse för virtuella tjänster och hur de lämpar sig för munvård.  Informationen som fås av undersökningen kan utnyttjas för att utveckla observationen, diagnostiken och förebyggandet av munsjukdomar. För att utveckla den automatiska observationen och identifieringen används och utvecklas data-analytik, artificiell syn och maskininlärning (artificiell intelligens).</p>
		
		<p>Alla myndiga frivilliga försökspersoner kan delta virtuellt i undersökningen, varvid antalet deltagare kan uppgå till flera tusen. Dessutom bjuds cirka 380 13–15-åriga ungdomar från Kuusamo och Ylivieska att delta i undersökningen.</p>
		
		<b>Undersökningens gång</b><br />
		<p>Deltagandet i undersökningen består av minst ett virtuellt undersökningsbesök, att frivilligt följa de egenvårdsanvisningar och -övningar som fås utifrån resultaten av enkäten om munhälsa och av att besvara klientenkäten.</p>
		
		<p>Det tar uppskattningsvis 30–45 minuter att delta i undersökningen. Då registrerar du dig till undersökningen och skapar ett användarkonto, besvarar enkäten som kartlägger hur du sköter din munhälsa, tar bilder på dina tänder med mobilkameran enligt anvisningarna och laddar upp de bilder du väljer för undersökningen i undersökningsapplikationen, får feedback om enkäten från applikationen och länkar till anvisningar om och/eller övningar för egenvård samt fyller i klientenkäten. </p>
		
		<p>När du loggar in på ditt användarkonto kan du se feedbacken och de anvisningar och/eller övningar som du fått även senare under undersökningen. Du kan också besvara enkäten på nytt, då du också får ny feedback, eller lägga till bilder.</p>
		
		<p>De uppgifter som samlas in om dig under undersökningen och de observationer som gjorts utifrån dem, förenas och jämförs genom statistiska metoder och maskininlärning med de uppgifter om din munhälsa som finns i din tjänsteleverantörs patientregister (t.ex. den offentliga hälso- och sjukvården, SHVS, Mehiläinen, Terveystalo, Plusterveys, Oral Hammaslääkärit, Coronaria, Pihlajalinna).</p>
		
		<p>Den lokala etiska kommittén vid Norra Österbottens sjukvårdsdistrikt har utvärderat undersökningsplanen och gett sitt bifall. Norra Österbottens sjukvårdsdistrikt har gett sitt tillstånd till undersökningen. Dessutom har man gjort en anmälan om undersökningen till Fimea och för att få uppgifter från patientregistret ansöker man om forskningstillstånd av Findata.</p>
		
		<b>Eventuella för- och nackdelar med undersökningen</b><br />
		<p>Det är möjligt att du inte får någon nytta av att du deltar i undersökningen. Du får virtuell feedback från applikationen utifrån enkäten och egenvårdsanvisningar och/eller övningar. Informationen som fås från undersökningen kan emellertid hjälpa till att utveckla förebyggande av munsjukdomar och tjänster på distans inom munhälsa.</p>
		
		<p>Det finns inga nackdelar med undersökningen med undantag av tiden som går åt.</p>
		
		<b>Behandling av personuppgifter och uppgifternas sekretess </b><br />
		<p>Personuppgifterna behandlas för vetenskapliga forskningsändamål. Insamlade uppgifter och forskningsresultat behandlas konfidentiellt såsom lagen förutsätter. Alla sektorer och personer som hanterar uppgifterna har tystnadsplikt. I samband med presentationen av resultaten kan man publicera bilder eller delar av dem som samlats in under undersökningen för att förtydliga undersökningen eller resultaten. En beskrivning av behandlingen av personuppgifter som sker inom undersökningen finns i undersökningens meddelande om skydd av personuppgifter.</p>
		
		<b>Undersökningens kostnader och ersättningar till den som deltar i undersökningen </b><br />
		<p>Undersökningen är avgiftsfri och det betalas ingen ersättning för deltagande i den.</p>
		
		<b>Undersökningens finansiering och forskarnas bundenheter</b><br />
		<p>Uleåborgs universitet och Norra Österbottens förbund med Europeiska unionens EAKR-projektfinansiering svarar för finansieringen av undersökningen. EAKR-projektets medfinansiärer är Dentview Oy, Tuudo Oy, Tecinspire Oy, Mehiläinen Oy, LokalTapiola Tjänster Ab och Colgate-Palmolive AB. Forskarläkarna och den övriga personalen får ingen separat ersättning för genomförandet av undersökningen. Forskarna har inga bindningar.</p>
		
		<b>Undersökningsdeltagarnas försäkringsskydd</b><br />
		<p>Undersökningsdeltagarna är försäkrade med Uleåborgs universitets patientförsäkring i undersökningen.</p>
		
		<p>Om det sker en personskada på grund av ett ingrepp under undersökningen kan du ansöka om ersättning. För personskador ansöker man om ersättning från forskningscentralenspatientförsäkring. Den ersätter i enlighet med patientförsäkringslagen personskador som orsakats i samband med hälso- och sjukvård, under förutsättningar som stadgas noggrannare i förordningen. Patientförsäkringscentralen ansvarar för ersättningen av patientskador.</p>
		
		<b>Informering om forskningsresultat</b><br />
		<p>Undersökningen är vetenskaplig, vilket innebär att det kan ta flera år innan resultaten är färdiga. Forskningsresultaten publiceras i ändamålsenliga nationella eller internationella vetenskapliga publikationer.</p>
		
		<p>Du meddelas inte personligen om forskningsresultaten efter undersökningen.</p>
		
		<p>Om det i forskningsresultaten av en slump observeras avvikande fynd som är betydande för din hälsa, bedömer en av tandläkarna inom undersökningen deras betydelse för din hälsa och anvisar dig vid behov till en lämplig plats för fortsatt vård.</p>
		
		<b>Undersökningens avslutningy</b><br />
		<p>Efter att undersökningen är slut fortsätter din vård som vanligt.</p>
		
		<b>Mer information</b><br />
		<p>Om du har frågor om undersökningen kan du ställa dem till Marja Liisa Laitala eller Katri Kukkola, eller per e-post också till adressen <a href="mailto:lupaloikata@oulu.fi">lupaloikata@oulu.fi</a>.</p>
		
		<b>Forskarnas kontaktuppgifter </b><br />
		<b>Forskningsansvarig</b><br />
		Professor (kariologi, endodonti och tandvård för barn)<br />
		Marja-Liisa Laitala<br />
		Enheten för munhälsa<br />
		+358 29 448 5423 / +358 40 678 6785<br />
		<a href="mailto:marja-liisa.laitala@oulu.fi">marja-liisa.laitala@oulu.fi</a><br />
		<br />
		<b>Huvudforskare</b><br />
		Forskardoktor<br />
		Saujanya Karki<br />
		Enheten för munhälsa<br />
		+358 29 448 5643/ +358 50 473 2337<br />
		<a href="mailto:saujanya.karki@oulu.fi">saujanya.karki@oulu.fi</a><br />
		<br />
		<b>Kontaktperson</b><br />
		Projektkoordinator<br />
		Katri Kukkola<br />
		Enheten för optoelektronik och mätningsteknik<br />
		+358 29 448 7694/ +358 50 350 4872<br />
		<a href="mailto:katri.kukkola@oulu.fi">katri.kukkola@oulu.fi</a><br />
	</Document>;
}

function feedbackGuidesEn(props) {
	let feedback = null;
	if (props.e["caries"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Caries</h2>
			<Video name="caries-en.mp4" /><br />
		</>;
	}
	if (props.e["erosion"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Erosion</h2>
			<Video name="erosion-en.mp4" /><br />
		</>;
	}
	if (props.e["caries"].level !== "low" || props.e["erosion"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Brushing</h2>
			<Video name="brushing-en.mp4" /><br />
		</>;
	}
	if (props.e["gum-disease"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Flossing</h2>
			<Video name="flossing-en.mp4" /><br />
		</>;
	}
	return feedback;
}

function feedbackGuidesFi(props) {
	let feedback = null;
	if (props.e["caries"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Karies</h2>
			<Video name="caries-fi.mp4" /><br />
		</>;
	}
	if (props.e["erosion"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Eroosio</h2>
			<Video name="erosion-fi.mp4" /><br />
		</>;
	}
	if (props.e["caries"].level !== "low" || props.e["erosion"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Hampaiden harjaaminen</h2>
			<Video name="brushing-fi.mp4" /><br />
		</>;
	}
	if (props.e["gum-disease"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Hammasvälien puhdistus</h2>
			<Video name="flossing-fi.mp4" /><br />
		</>;
	}
	return feedback;
}

function feedbackGuidesSe(props) {
	let feedback = null;
	if (props.e["caries"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Karies</h2>
			<Video name="caries-se.mp4" /><br />
		</>;
	}
	if (props.e["erosion"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Erosion</h2>
			<Video name="erosion-se.mp4" /><br />
		</>;
	}
	if (props.e["caries"].level !== "low" || props.e["erosion"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Borsta tänderna</h2>
			<Video name="brushing-se.mp4" /><br />
		</>;
	}
	if (props.e["gum-disease"].level !== "low") {
		feedback = <>
			{feedback}
			<h2>Tandtråd</h2>
			<Video name="flossing-se.mp4" /><br />
		</>;
	}
	return feedback;
}

function feedbackFearNoneEn(props) {
	return <>
		You chose green to indicate that you are not afraid of dental treatment or procedures. In order to keep the situation in good shape, please take care of your teeth. 
		<ul>
			<li>Brushing, fluoride paste and a healthy diet are important for your dental health.</li>
			<li>Get a dental examination at the recommended intervals.</li>
			<li>If you are nervous about something in dental treatment situations, tell your dentist.
				<ul>
					<li>Together with your dentist, you can plan how best to cope with the dental treatment situation.</li>
					<li>For example, you can arrange for a break, a stop sign or pain relief. You can also listen to music if you like.</li>
				</ul>
			</li>
		</ul>
	</>;
}

function feedbackFearNoneFi(props) {
	return <>
		Valitsit vihreän värin kuvaamaan sitä, että et pelkää hammashoitoa etkä toimenpiteitä. Jotta tilanne säilyisi hyvänä, huolehdithan hampaistasi.
		<ul>
			<li>Harjaus, fluoritahna ja terveellinen ruokavalio ovat tärkeitä hampaittesi terveydelle.</li>
			<li>Käy hammastarkastuksessa sinulle suositelluin määrävälein.</li>
			<li>Jos sinua jännittää jokin asia hammashoitotilanteessa, kerro se hoitavalle hammaslääkärille.
				<ul>
					<li>Voitte yhdessä hammaslääkärisi kanssa suunnitella, miten voit parhaiten selvitä hammashoitotilanteessa.</li>
					<li>Voitte sopia esimerkiksi tauotuksesta, keskeyttämismerkistä tai kivunlievityksestä. Voit myös halutessasi kuunnella musiikkia.</li>
				</ul>
			</li>
		</ul>
	</>;
}

function feedbackFearNoneSe(props) {
	return <>
		Du valde grönt för att du inte är rädd för tandvård eller ingrepp. För att situationen ska förbli bra ska du sköta om dina tänder. 
		<ul>
			<li>Att borsta tänderna med fluortandkräm och en hälsosam kost är viktiga för din tandhälsa.</li>
			<li>Gå på tandundersökningar så ofta som du har blivit rekommenderad.</li>
			<li>Om du är rädd för något som görs under ett tandläkarbesök ska du berätta det för tandläkaren.
				<ul>
					<li>Tillsammans med tandläkaren kan ni planera hur du bäst kan klara av situationen.</li>
					<li>Ni kan till exempel bestämma att ta pauser, att du visar ett tecken på att avbryta eller att du får smärtlindring. Du kan också lyssna på musik om du vill.</li>
				</ul>
			</li>
		</ul>
	</>;
}

function feedbackFearLowEn(props) {
	return <>
		<p>You chose the yellow or red traffic light to indicate that you are at least somewhat afraid of dental treatment. The score you got in the MDAS dental phobia questionnaire ({props.points}/25) shows that you have a mild phobia.</p>
		<ul>
			<li>If you are nervous about something in dental treatment situations, tell your dentist.
				<ul>
					<li>Together with your dentist, you can plan how best to cope with the dental treatment situation.</li>
					<li>For example, you can arrange for a break, a stop sign or pain relief. You can also listen to music if you like.</li>
				</ul>
			</li>
			<li>You can also practise relaxing.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-en.mp4" /><br />
			</li>
			<li>Please take care of your teeth.</li>
			<li>Also keep in mind that brushing, fluoride paste and a healthy diet are important for your dental health.</li>
			<li>Get a dental examination at the recommended intervals.</li>
			<li>Regular dental care prevents the accumulation of problems and keeps the need for treatment from becoming too great. This prevents your fear from growing and increases your courage.</li>
		</ul>
	</>;
}

function feedbackFearLowFi(props) {
	return <>
		<p>Valitsit keltaisen tai punaisen värin kuvaamaan sitä, että pelkäät hammashoitoa ainakin jonkin verran. Hammashoitopelkoa kartoittavassa MDAS-kyselyssä saamasi pisteet ({props.points}/25) osoittavat, että pelkosi on lievää.</p>
		<ul>
			<li>Jos sinua jännittää jokin asia hammashoitotilanteessa, kerro se hoitavalle hammaslääkärille.
				<ul>
					<li>Voitte yhdessä hammaslääkärisi kanssa suunnitella, miten voit parhaiten selvitä hammashoitotilanteessa.</li>
					<li>Voitte sopia esimerkiksi tauotuksesta, keskeyttämismerkistä tai kivunlievityksestä. Voit myös halutessasi kuunnella musiikkia.</li>
				</ul>
			</li>
			<li>Voit myös harjoitella rentoutumista.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-fi.mp4" /><br />
			</li>
			<li>Huolehdithan hampaistasi.</li>
			<li>Muista myös, että harjaus, fluoritahna ja terveellinen ruokavalio ovat tärkeitä hampaittesi terveydelle.</li>
			<li>Käy hammastarkastuksessa sinulle suositelluin määrävälein.</li>
			<li> Säännöllinen hammashoito estää ongelmien kasaantumisen, eikä hoidon tarve kasva liian suureksi. Näin estät pelkoasi kasvamasta, ja rohkeutesi lisääntyy.</li>
		</ul>
	</>;
}

function feedbackFearLowSe(props) {
	return <>
		<p>Du valde gult eller rött för att du är rädd för tandvård åtminstone i någon mån. Poängen som du fick i MDAS-enkäten som kartlägger tandvårdsrädsla ({props.points}/25) visar att din rädsla är mild.</p>
		<ul>
			<li>Om du är rädd för något som görs under ett tandläkarbesök ska du berätta det för tandläkaren.
				<ul>
					<li>Tillsammans med tandläkaren kan ni planera hur du bäst kan klara av situationen.</li>
					<li>Ni kan till exempel bestämma att ta pauser, att du visar ett tecken på att avbryta eller att du får smärtlindring. Du kan också lyssna på musik om du vill.</li>
				</ul>
			</li>
			<li>Du kan också träna på att slappna av.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-se.mp4" /><br />
			</li>
			<li>Sköt om dina tänder.</li>
			<li>Kom också ihåg att det är viktigt för din tandhälsa att borsta tänderna med fluortandkräm och hålla en hälsosam kost.</li>
			<li>Gå på tandundersökningar så ofta som du har blivit rekommenderad.</li>
			<li>Regelbunden tandvård förebygger att problemen hopar sig, och vårdbehovet blir inte för stort. På så sätt hindrar du att din rädsla ökar och du blir modigare.</li>
		</ul>
	</>;
}

function feedbackFearMidEn(props) {
	return <>
		<p>You chose the yellow/red traffic light to indicate that you are at least somewhat afraid of dental treatment. The score you got in the MDAS dental phobia questionnaire ({props.points}/25) shows that you have a moderate dental phobia. Your phobia may be related to a procedure, such as anaesthesia, cavity filling or the whole situation.</p>
		<ul>
			<li>Tell your dentist about your phobia as soon as you go to the dentist, or mention it when you make your appointment.
				<ul>
					<li>Together with your dentist, you can plan how best to cope with the dental treatment situation and how your dental treatment will be managed.</li>
					<li>For example, you can arrange for a break, a stop sign and pain relief.</li>
					<li>It is important that you agree on the next steps after each treatment situation.</li>
				</ul>
			</li>
			<li>You can listen to your favourite music.</li>
			<li>You can also practise relaxing.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-en.mp4" /><br />
			</li>
			<li>You can also do a two-minute mental imagery exercise and relax by watching a video or image of your choice, to which you can return to in the treatment situation.<br />
				<Video name="hiihto.mp4" />
			</li>
			<li>You can practise shifting your focus, which may help you feel better in a treatment situation.<br />
				<Video name="pallo-en.mp4" /><br />
			</li>
			<li>Please take care of your teeth.</li>
			<li>Also keep in mind that brushing, fluoride paste and a healthy diet are important for your dental health.</li>
			<li>Get a dental examination at the recommended intervals.</li>
			<li>Regular dental care prevents the accumulation of problems and keeps the need for treatment from becoming too great. This prevents your fear from growing and increases your courage.</li>
		</ul>
	</>;
}

function feedbackFearMidFi(props) {
	return <>
		<p>Valitsit keltaisen tai punaisen värin kuvaamaan sitä, että pelkäät hammashoitoa ainakin jonkin verran. Hammashoitopelkoa kartoittavassa MDAS-kyselyssä saamasi pisteet ({props.points}/25) osoittavat, että hammashoitopelkosi on kohtalaista. Pelkosi voi liittyä johonkin toimenpiteeseen, kuten esimerkiksi puudutukseen, paikkaukseen tai koko tilanteeseen.</p>
		<ul>
			<li>Kerro pelostasi hoitavalle hammaslääkärille heti, kun menet hammaslääkärille, tai voit kertoa pelostasi jo aikaa varatessasi.
				<ul>
					<li>Voitte yhdessä hammaslääkärisi kanssa suunnitella, miten voit parhaiten selvitä hammashoitotilanteessa ja miten hampaasi saadaan hoidettua.</li>
					<li>Voitte sopia esimerkiksi tauotuksesta, keskeyttämismerkistä ja kivunlievityksestä.</li>
					<li>On tärkeää, että sovitte jokaisen hoitotilanteen jälkeen, miten jatkossa toimitaan.</li>
				</ul>
			</li>
			<li>Voit kuunnella lempimusiikkiasi.</li>
			<li>Voit myös harjoitella rentoutumista.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-fi.mp4" /><br />
			</li>
			<li>Voit tehdä myös kahden minuutin mittaisen mielikuvaharjoituksen ja rentoutua katselemalla valitsemaasi videota tai kuvaa, johon voit palata hoitotilanteessa.<br />
				<Video name="hiihto.mp4" />
			</li>
			<li>Voit harjoitella huomion siirtoa, joka voi helpottaa oloasi hoitotilanteessa.<br />
				<Video name="pallo-fi.mp4" /><br />
			</li>
			<li>Huolehdithan hampaistasi.</li>
			<li>Muista myös, että harjaus, fluoritahna ja terveellinen ruokavalio ovat tärkeitä hampaittesi terveydelle.</li>
			<li>Käy hammastarkastuksessa sinulle suositelluin määrävälein.</li>
			<li> Säännöllinen hammashoito estää ongelmien kasaantumisen, eikä hoidon tarve kasva liian suureksi. Näin estät pelkoasi kasvamasta, ja rohkeutesi lisääntyy.</li>
		</ul>
	</>;
}

function feedbackFearMidSe(props) {
	return <>
		<p>Du valde gult eller rött för att du är rädd för tandvård åtminstone i någon mån. Poängen som du fick i MDAS-enkäten som kartlägger tandvårdsrädsla ({props.points}/25) visar att din rädsla är måttlig. Din rädsla kan anknyta till något ingrepp, såsom bedövning, lagning eller hela situationen.</p>
		<ul>
			<li>Berätta om din rädsla för tandläkaren genast när du kommer till mottagningen, eller så kan du berätta om din rädsla redan då du bokar tid.
				<ul>
					<li>Tillsammans med tandläkaren kan ni planera hur du bäst kan klara av situationen och hur dina tänder kan behandlas.</li>
					<li>Ni kan till exempel bestämma att ta pauser, att du visar ett tecken på att avbryta och att du får smärtlindring.</li>
					<li>Det är viktigt att ni efter varje situation bestämmer hur ni gör i fortsättningen.</li>
				</ul>
			</li>
			<li>Du kan lyssna på din favoritmusik.</li>
			<li>Du kan också träna på att slappna av.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-fi.mp4" /><br />
			</li>
			<li>Du kan göra en två minuters fantasiövning, och slappna av genom att titta på en video eller en bild som du kan återkomma till i behandlingssituationen.<br />
				<Video name="hiihto.mp4" />
			</li>
			<li>Du kan träna på att flytta din uppmärksamhet, vilket kan underlätta ditt mående i behandlingssituationen.<br />
				<Video name="pallo-fi.mp4" /><br />
			</li>
			<li>Sköt om dina tänder. </li>
			<li>Kom också ihåg att det är viktigt för din tandhälsa att borsta tänderna med fluortandkräm och hålla en hälsosam kost.</li>
			<li>Gå på tandundersökningar så ofta som du har blivit rekommenderad.</li>
			<li>Regelbunden tandvård förebygger att problemen hopar sig, och vårdbehovet blir inte för stort. På så sätt hindrar du att din rädsla ökar och du blir modigare.</li>
		</ul>
	</>;
}

function feedbackFearHighEn(props) {
	return <>
		<p>You chose the yellow or red traffic light to indicate that you are at least somewhat afraid of dental treatment. The score you got in the MDAS dental phobia questionnaire ({props.points}/25) shows that you have a strong dental phobia. Your phobia may be related to a procedure, such as anaesthesia, cavity filling or the whole situation. Because of the phobia, just going to a dentist can be frightening. </p>
		<ul>
			<li>You can mention your phobia when you make your appointment. Mention your phobia at the latest when you go to the dentist. In Finland, there are dentists who have studied the treatment patients who have dental phobia.
				<ul>
					<li>Together with your dentist, you can plan how best to cope with the dental treatment situation and how your dental treatment will be managed.</li>
					<li>You may need a sedative pre-medication or, for example, a nitrous oxide treatment or even dental anaesthesia: please consult your dentist.</li>
					<li>It is important to arrange for breaks, a stop sign and pain relief in dental treatment in advance.</li>
					<li>It is important that you agree on the next steps after each treatment situation.</li>
				</ul>
			</li>
			<li>You can listen to your favourite music.</li>
			<li>You can also practise relaxing.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-en.mp4" /><br />
			</li>
			<li>You can also do a two-minute mental imagery exercise and relax by watching a video or image of your choice, to which you can return to in the treatment situation.<br />
				<Video name="hiihto.mp4" />
			</li>
			<li>You can practise shifting your focus, which may help you feel better in a treatment situation.<br />
				<Video name="pallo-en.mp4" /><br />
			</li>
			<li>Please take care of your teeth..</li>
			<li>Also keep in mind that brushing, fluoride paste and a healthy diet are important for your dental health.</li>
			<li>Get a dental examination at the recommended intervals.</li>
			<li>Regular dental care prevents the accumulation of problems and keeps the need for treatment from becoming too great. This prevents your fear from growing and increases your courage.</li>
		</ul>
	</>;
}

function feedbackFearHighFi(props) {
	return <>
		<p>Valitsit keltaisen tai punaisen värin kuvaamaan sitä, että pelkäät hammashoitoa ainakin jonkin verran. Hammashoitopelkoa kartoittavassa MDAS-kyselyssä saamasi pisteet ({props.points}/25) osoittavat, että hammashoitopelkosi on voimakasta. Pelkosi voi liittyä johonkin toimenpiteeseen, kuten esimerkiksi puudutukseen, paikkaukseen tai koko tilanteeseen. Pelon takia jopa hakeutuminen hammaslääkäriin voi pelottaa.</p>
		<ul>
			<li>Voit kertoa pelostasi aikaa varatessasi. Kerro pelostasi viimeistään, kun menet vastaanotolle. Suomessa on hammaslääkäreitä, jotka ovat perehtyneet hoitamaan pelkääviä potilaita.
				<ul>
					<li>Voitte yhdessä hammaslääkärisi kanssa suunnitella, miten voit parhaiten selvitä hammashoitotilanteessa ja miten hampaasi saadaan hoidettua.</li>
					<li>Voit tarvita rauhoittavaa esilääkettä tai esimerkiksi ilokaasuhoitoa tai jopa nukutushammashoitoa: neuvottele asiasta hammaslääkärisi kanssa.</li>
					<li>On tärkeää sopia etukäteen tauotuksesta, keskeyttämismerkistä ja kivunlievityksestä hammashoidossa.</li>
					<li>On tärkeää, että sovitte jokaisen hoitotilanteen jälkeen, miten jatkossa toimitaan.</li>
				</ul>
			</li>
			<li>Voit kuunnella lempimusiikkiasi.</li>
			<li>Voit myös harjoitella rentoutumista.<br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-fi.mp4" /><br />
			</li>
			<li>Voit tehdä myös kahden minuutin mittaisen mielikuvaharjoituksen ja rentoutua katselemalla valitsemaasi videota tai kuvaa, johon voit palata hoitotilanteessa.<br />
				<Video name="hiihto.mp4" />
			</li>
			<li>Voit harjoitella huomion siirtoa, joka voi helpottaa oloasi hoitotilanteessa.<br />
				<Video name="pallo-fi.mp4" /><br />
			</li>
			<li>Huolehdithan hampaistasi.</li>
			<li>Muista myös, että harjaus, fluoritahna ja terveellinen ruokavalio ovat tärkeitä hampaittesi terveydelle.</li>
			<li>Käy hammastarkastuksessa sinulle suositelluin määrävälein.</li>
			<li>Säännöllinen hammashoito estää ongelmien kasaantumisen, eikä hoidon tarve kasva liian suureksi. Näin estät pelkoasi kasvamasta, ja rohkeutesi lisääntyy.</li>
		</ul>
	</>;
}

function feedbackFearHighSe(props) {
	return <>
		<p>Du valde gult eller rött för att du är rädd för tandvård åtminstone i någon mån. Poängen som du fick i MDAS-enkäten som kartlägger tandvårdsrädsla ({props.points}/25) visar att din rädsla är stark. Din rädsla kan anknyta till något ingrepp, såsom bedövning, lagning eller hela situationen. På grund av rädslan kan du till och med vara rädd att boka en tid till en undersökning.</p>
		<ul>
			<li>Du kan berätta om din rädsla när du bokar tid. Berätta om din rädsla senast när du kommer till mottagningen. I Finland finns tandläkare som har erfarenhet av att behandla rädda patienter.
				<ul>
					<li>Tillsammans med tandläkaren kan ni planera hur du bäst kan klara av situationen och hur dina tänder kan behandlas.</li>
					<li>Du kan behöva lugnande premedicinering eller till exempel lustgas eller till och med tandvård i narkos. Diskutera saken med din tandläkare.</li>
					<li>Det är viktigt att ni på förhand bestämmer om att ta pauser, att du visar ett tecken på att avbryta och att du får smärtlindring.</li>
					<li>Det är viktigt att ni efter varje situation bestämmer hur ni gör i fortsättningen.</li>
				</ul>
			</li>
			<li>Du kan lyssna på din favoritmusik.</li>
			<li>Du kan också träna på att slappna av. <br />
				<Video name="joki.mp4" /><br />
				<Video name="maa-se.mp4" /><br />
			</li>
			<li>Du kan göra en två minuters fantasiövning, och slappna av genom att titta på en video eller en bild som du kan återkomma till i behandlingssituationen. <br />
				<Video name="hiihto.mp4" />
			</li>
			<li>Du kan träna på att flytta din uppmärksamhet, vilket kan underlätta ditt mående i behandlingssituationen.<br />
				<Video name="pallo-se.mp4" /><br />
			</li>
			<li>Sköt om dina tänder. </li>
			<li>Kom också ihåg att det är viktigt för din tandhälsa att borsta tänderna med fluortandkräm och hålla en hälsosam kost.</li>
			<li>Gå på tandundersökningar så ofta som du har blivit rekommenderad.</li>
			<li>Regelbunden tandvård förebygger att problemen hopar sig, och vårdbehovet blir inte för stort. På så sätt hindrar du att din rädsla ökar och du blir modigare. </li>
		</ul>
	</>;
}

function feedbackRiskLowEn(props) {
	return <>
		Based on your responses, your oral hygiene habits favour the health of your teeth and gums. However, regular dental examinations are important, so a dentist's examination within two years is recommended.
	</>;
}

function feedbackRiskLowFi(props) {
	return <>
		Vastauksiesi perusteella suuhygieniatottumuksesi suosivat hampaiden ja ikenien terveyttä. Säännölliset hammastarkastukset ovat kuitenkin tärkeitä, joten hammaslääkärin tutkimus kahden vuoden kuluessa on suositeltavaa.
	</>;
}

function feedbackRiskLowSe(props) {
	return <>
		Utifrån dina svar främjar dina munhygienvanor dina tänders och ditt tandkötts hälsa. Regelbundna kontroller är ändå viktiga, så en tandundersökning inom två år rekommenderas.
	</>;
}

function feedbackRiskMidEn(props) {
	let risks = [];
	if (props.e["caries"].level === "mid") {
		risks.push("caries (tooth decay)");
	}
	if (props.e["erosion"].level === "mid") {
		risks.push("dental erosion (loss of tooth enamel)");
	}
	if (props.e["gum-disease"].level === "mid") {
		risks.push("periodontitis (gum disease)");
	}
	return <>
		You should seek a dentist's examination within six months. Based on your responses, your eating and oral hygiene habits pose a possible risk of {risks.join(", ")}.
	</>;
}

function feedbackRiskMidFi(props) {
	let risks = [];
	if (props.e["caries"].level === "mid") {
		risks.push("hampaiden reikiintymiselle eli kariekselle");
	}
	if (props.e["erosion"].level === "mid") {
		risks.push("kiilteen liukenemiselle eli hammaseroosiolle");
	}
	if (props.e["gum-disease"].level === "mid") {
		risks.push("parodontiittille eli hampaiden kiinnityskudossairaudelle");
	}
	return <>
		Sinun kannattaa hakeutua hammaslääkärin tutkimukseen kuuden kuukauden kuluessa. Vastauksiesi perusteella ruokailu- ja suuhygieniatottumuksesi ovat mahdollinen riski {risks.join(", ")}.
	</>;
}

function feedbackRiskMidSe(props) {
	let risks = [];
	if (props.e["caries"].level === "mid") {
		risks.push("hål i tänderna, alltså karies");
	}
	if (props.e["erosion"].level === "mid") {
		risks.push("att tandemaljen löses upp, alltså tanderosion");
	}
	if (props.e["gum-disease"].level === "mid") {
		risks.push("parodontit, alltså att fästvävnaden förstörs");
	}
	return <>
		Det rekommenderas att du genomgår en tandundersökning inom sex månader. Utifrån dina svar utgör dina mat- och munhygienvanor en eventuell risk för {risks.join(", ")}.
	</>;
}

function feedbackRiskHighEn(props) {
	let risksHigh = [];
	let risksMid = [];
	if (props.e["caries"].level === "high") {
		risksHigh.push("caries (tooth decay)");
	}
	else if (props.e["caries"].level === "mid") {
		risksMid.push("caries (tooth decay)");
	}
	if (props.e["erosion"].level === "high") {
		risksHigh.push("dental erosion (loss of tooth enamel)");
	}
	else if (props.e["erosion"].level === "mid") {
		risksMid.push("dental erosion (loss of tooth enamel)");
	}
	if (props.e["gum-disease"].level === "high") {
		risksHigh.push("periodontitis (gum disease)");
	}
	else if (props.e["gum-disease"].level === "mid") {
		risksMid.push("periodontitis (gum disease)");
	}
	return <>
		A dentist's examination is recommended as soon as possible. Based on your responses, your eating and oral hygiene habits pose a risk of {risksHigh.join(", ")}.
		{risksMid.length !== 0 ? " In addition, they pose a possible risk of " + risksMid.join(", ") + "." : ""}
	</>;
}

function feedbackRiskHighFi(props) {
	let risksHigh = [];
	let risksMid = [];
	if (props.e["caries"].level === "high") {
		risksHigh.push("hampaiden reikiintymiselle eli kariekselle");
	}
	else if (props.e["caries"].level === "mid") {
		risksMid.push("hampaiden reikiintymiselle eli kariekselle");
	}
	if (props.e["erosion"].level === "high") {
		risksHigh.push("kiilteen liukenemiselle eli hammaseroosiolle");
	}
	else if (props.e["erosion"].level === "mid") {
		risksMid.push("kiilteen liukenemiselle eli hammaseroosiolle");
	}
	if (props.e["gum-disease"].level === "high") {
		risksHigh.push("parodontiittille eli hampaiden kiinnityskudossairaudelle");
	}
	else if (props.e["gum-disease"].level === "mid") {
		risksMid.push("parodontiittille eli hampaiden kiinnityskudossairaudelle");
	}
	return <>
		Hammaslääkärin tutkimus mahdollisimman pian on suositeltavaa. Vastauksiesi perusteella ruokailu- ja suuhygieniatottumuksesi ovat riski {risksHigh.join(", ")}.
		{risksMid.length !== 0 ? " Lisäksi ne ovat mahdollinen riski " + risksMid.join(", ") + "." : ""}
	</>;
}

function feedbackRiskHighSe(props) {
	let risksHigh = [];
	let risksMid = [];
	if (props.e["caries"].level === "high") {
		risksHigh.push("hål i tänderna, alltså karies");
	}
	else if (props.e["caries"].level === "mid") {
		risksMid.push("hål i tänderna, alltså karies");
	}
	if (props.e["erosion"].level === "high") {
		risksHigh.push("att tandemaljen löses upp, alltså tanderosion");
	}
	else if (props.e["erosion"].level === "mid") {
		risksMid.push("att tandemaljen löses upp, alltså tanderosion");
	}
	if (props.e["gum-disease"].level === "high") {
		risksHigh.push("parodontit, alltså att fästvävnaden förstörs");
	}
	else if (props.e["gum-disease"].level === "mid") {
		risksMid.push("parodontit, alltså att fästvävnaden förstörs");
	}
	return <>
		En tandundersökning så snart som möjligt rekommenderas. Utifrån dina svar utgör dina mat- och munhygienvanor en risk för {risksHigh.join(", ")}.
		{risksMid.length !== 0 ? " Dessutom utgör de eventuellt en risk för " + risksMid.join(", ") + "." : ""}
	</>;
}

function sugarPerDay(points) {
	return points.toFixed(0);
}

function sugarPerYear(points) {
	return (points*365/1000).toFixed(0);
}

function feedbackSugarEn(props) {
	return <>
		Based on your responses, your average daily intake of free sugars is {sugarPerDay(props.points)} g/day or {sugarPerYear(props.points)} kg/year.
	</>;
}

function feedbackSugarFi(props) {
	return <>
		Vastauksiesi perusteella keskimääräinen päivittäinen vapaan sokerin saantisi on {sugarPerDay(props.points)} g/vuorokaudessa eli {sugarPerYear(props.points)} kg/vuodessa.
	</>;
}

function feedbackSugarSe(props) {
	return <>
		Utifrån dina svar är ditt genomsnittliga dagliga intag av fritt socker {sugarPerDay(props.points)} g/dygn, alltså {sugarPerYear(props.points)} kg/år. 
	</>;
}

function Copyright(props) {
	return <>Copyright &copy; 2021-{(new Date()).getFullYear()} <a href="mailto:eero.molkoselka@gmail.com">Eero Molkoselkä</a></>;
}

function textAboutEn(props) {
	return <>
		<h2>Dentogram {props.version} ({props.date})</h2>
		<p><Copyright />, University of Oulu, Pentti Kaiterankatu 1, FI-90570 Oulu</p>
		<br />
		
		<p><u>Exclusively for clinical investigation.</u></p>
		
		<p>Dentogram is intended for the virtual screening, prevention and management of oral diseases, as well as for the screening and management of dental phobia with self-care.</p>
		
		<p>The oral health risk assessment service is still in the research phase, so its reliability has not yet been verified as required for a medical device. The assessment of dental care scare utilizes Humphris, G.M., Morrisison, T. and Lindsay, S.J.E. (1995) 'The Modified Dental Anxiety Scale: Validation and United Kingdom Norms' Community Dental Health, 12, 143-150 surveys developed in the study. If you have any oral health problems or questions, ask your dentist or dental hygienist.</p>
	</>;
}

function textAboutFi(props) {
	return <>
		<h2>Dentogram {props.version} ({props.date})</h2>
		<p><Copyright />, Oulun yliopisto, Pentti Kaiterankatu 1, 90570 Oulu</p>
		<br />
		
		<p><u>Ainoastaan kliinisiin tutkimuksiin.</u></p>
		
		<p>Dentogram on tarkoitettu virtuaaliseen suusairauksien seulontaan, ennaltaehkäisyyn ja hallintaan sekä hammaslääkäripelon seulontaan ja hallintaan omahoidolla.</p>
		
		<p>Suunterveyden riskinarviointipalvelu on vasta tutkimusvaiheessa, joten sen luotettavuutta ole vielä todennettu lääkinnälliseltä laitteelta vaadittavalla tavalla. Hammashoitopelon arvioinnissa hyödynnetään Humphris, G.M., Morrisison, T. and Lindsay, S.J.E. (1995) 'The Modified Dental Anxiety Scale: Validation and United Kingdom Norms' Community Dental Health, 12, 143-150  tutkimuksessa kehitettyä kyselyä. Jos koet, että sinulla on suunterveyteen liittyviä ongelmia tai kysymyksiä, käänny hammaslääkärin tai suuhygienistin puoleen.</p>
	</>;
}

function textAboutSe(props) {
	return <>
		<h2>Dentogram {props.version} ({props.date})</h2>
		<p><Copyright />, Uleåborgs universitet, Pentti Kaiterankatu 1, 90570 Uleåborg</p>
		<br />
		
		<p><u>Endast för klinisk prövning.</u></p>
		
		<p>Dentogram är avsedd för virtuell screening, förebyggande och kontroll av munsjukdomar, samt screening och kontroll av tandvårdsrädsla genom egenvård.</p>
		
		<p>Riskbedömningstjänsten för munhälsa är ännu i undersökningsskedet, alltså har dess pålitlighet ännu inte bekräftats på det sätt som krävs av medicinska verktyg. Bedömningen av tandvård skrämmande använder Humphris, G.M., Morrisison, T. och Lindsay, S.J.E. (1995) 'The Modified Dental Ångest Scale: Validation and United Kingdom Norms' Community Dental Health, 12, 143-150 undersökningar som utvecklats i studien.</p>
	</>;
}

function textCookiesEn(props) {
	return <p>
		Our site uses cookies. When you visit our website, it stores cookies, i.e. small text files on your terminal. All cookies on the site are technical cookies to verify the operation of the site. <a href="static/doc/cookies-en.pdf" target="_blank">About cookies</a>.
	</p>;
}

function textCookiesFi(props) {
	return <p>
		Sivustomme käyttää evästeitä. Kun vierailet verkkopalvelumme sivulla, sivustomme tallentaa päätelaitteellesi evästeitä eli pieniä tekstitiedostoja. Kaikki sivuston evästeet ovat teknisiä evästeitä sivuston toiminnan varmentamiseksi. <a href="static/doc/cookies-fi.pdf" target="_blank">Tietoa evästeistä</a>.
	</p>;
}

function textCookiesSe(props) {
	return <p>
		Vår webbplats använder cookies. När du besöker vår webbsida lagrar vår webbplats cookies, dvs. små textfiler på din terminal. Alla cookies på webbplatsen är tekniska cookies för att säkerställa funktionaliteten.. Cookies tillåter till exempel en webbsida att komma ihåg vilket språk du vill använda vår webbplats. <a href="static/doc/cookies-se.pdf" target="_blank">Information om cookies</a>.
	</p>;
}

function textHomeEn(props) {
	return <>
		<p>Welcome! Your username is <b>{props.username}</b>. The name has been randomly generated to ensure your own security.</p>
		Application usage:<br />
		<ol>
			<li>Write down your username</li>
			<li>Answer the questionnaire</li>
			<li>Take and send pictures of your teeth</li>
			<li>View your results</li>
			<li>Give us feedback</li>
		</ol>
	</>;
}

function textHomeFi(props) {
	return <>
		<p>Tervetuloa! Käyttäjänimesi on <b>{props.username}</b>. Nimi on luotu satunnaisesti tietoturvasi takaamiseksi.</p>
		Sovelluksen käyttö:<br />
		<ol>
			<li>Kirjoita muistiin käyttäjänimesi</li>
			<li>Vastaa kyselyyn</li>
			<li>Ota ja lähetä kuvat hampaistasi</li>
			<li>Katso tuloksesi</li>
			<li>Anna meille palautetta</li>
		</ol>
	</>;
}


function textHomeSe(props) {
	return <>
		<p>Välkommen! Ditt användarnamn är <b>{props.username}</b>. Namnet har skapats slumpmässigt för att säkerställa din informationssäkerhet.</p>
		Användning av applikationen:<br />
		<ol>
			<li>Skriv upp ditt användarnamn så att du inte glömmer det</li>
			<li>Besvara enkäten</li>
			<li>Ta och skicka bilderna på dina tänder</li>
			<li>Läs dina svar</li>
			<li>Ge oss feedback</li>
		</ol>
	</>;
}

function textImagesEn(props) {
	return <>
		<p>On this page you can take pictures of your teeth with a smartphone. You can also view your questionnaire results using the button above. The images can also be added later.</p>
		<p>Replace the placeholder images with your own mouth images from the same directions. It is preferable to use continuous camera flash when taking the images. More guidance is available in the imaging guide.</p>
	</>;
}

function textImagesFi(props) {
	return <>
		<p>Tällä sivulla voit ottaa kuvia hampaistasi älypuhelimella. Voit myös katsoa kyselytuloksesi ylläolevalla painikkeella. Kuvat voi lisätä myös myöhemmin.</p>
		<p>Korvaa mallikuvat omasta suustasi samasta suunnasta otetuilla kuvilla. Kuvissa olisi hyvä käyttää puhelimen soihtuvalo-ominaisuutta eli jatkuvaa salamavaloa. Lisäohjeita saat kuvausohjeista.</p>
	</>;
}

function textImagesSe(props) {
	return <>
		<p>På den här sidan kan du ta bilder av dina tänder med en smarttelefon. Du kan också se enkätsvaren genom att klicka på knappen ovan. Du kan lägga till bilder även senare.</p>
		<p>Ersätt modellbilderna med bilderna som du tagit på din mun från samma håll. Det skulle vara bra att använda blixtljus som lyser hela tiden. Närmare anvisningar finns i fotograferingsanvisningen.</p>
	</>;
}

const STRINGS = {
	"btn-accept-no": ["Don't accept", "En hyväksy", "Acceptera inte"],
	"btn-accept-privacy": ["I accept the privacy statement", "Hyväksyn tietosuojailmoituksen", "Jag accepterar integritetspolicyn"],
	"btn-accept-study": ["I agree to participate in the study", "Suostun tutkimukseen", "Jag samtycker till att delta i studien"],
	"btn-accept-yes": ["Accept", "Hyväksyn", "Acceptera"],
	"btn-back": ["Back", "Takaisin", "Tillbaka"],
	"btn-delete": ["Delete", "Poista", "Ta bort"],
	"btn-feedback": ["Feedback questionnaire", "Palautekysely", "Feedbackundersökning"],
	"btn-images": ["Images", "Kuvat", "Bilder"],
	"btn-next": ["Next", "Seuraava", "Nästä"],
	"btn-open-pdf": ["Open PDF", "Avaa PDF", "Öppna PDF"],
	"btn-prev": ["Previous", "Edellinen", "Tidigare"],
	"btn-questionnaire": ["To the questionnaire!", "Kyselyyn!", "Till enkäten!"],
	"btn-reset": ["Reset", "Tyhjennä", "Tön"],
	"btn-results": ["Results", "Tulokset", "Resultaten"],
	"btn-set-primary": ["Set as primary", "Aseta ensisijaiseksi", "Ange som primär"],
	"btn-submit": ["Submit", "Lähetä", "Skicka"],
	
	"error-no-cookies": ["The application does not work without cookies.", "Sovellus ei toimi ilman evästeitä.", "Applikationen fungerar inte utan kakor."],
	"error-password-match": ["Passwords don't match", "Salasanat eivät täsmää.", "Lösenorden är inte samma"],
	
	"doc-imaging": [docImagingEn, docImagingFi, docImagingSe],
	"doc-consent": [docConsentEn, docConsentFi, docConsentSe],
	"doc-privacy-statement": [docPrivacyStatementEn, docPrivacyStatementFi, docPrivacyStatementSe],
	"doc-research-info": [docResearchInfoEn, docResearchInfoFi, docResearchInfoSe],
	
	"field-confirm-password": ["Confirm password", "Vahvista salasana", "Bekräfta lösenordet"],
	"field-code": ["Registration code", "Rekisteröintikoodi", "Registreringskod"],
	"field-dentist": ["Dentist", "Hammashoitola", "Tandklinik"],
	"field-email": ["Email", "Sähköpostiosoite", "E-postadress"],
	"field-password": ["Password", "Salasana", "Lösenord"],
	"field-phone": ["Phone", "Puhelinnumero", "Telefonnummer"],
	"field-ssn": ["Personal identity code", "Henkilötunnus", "Personbeteckning"],
	"field-code-account": ["Student", "Koululainen", "Elev"],
	"field-shib-account": ["Adult", "Aikuinen", "Vuxen"],
	"field-username": ["Username", "Käyttäjätunnus", "Användarnamn"],
	"field-test-account": ["Test account", "Testikäyttäjä", "Testanvändare "],
	
	"h-caries": ["Caries", "Karies", "Karies"],
	"h-digileap": ["Let's leap!", "Lupa loikata!", "Låt oss hoppa!"],
	"h-erosion": ["Erosion", "Eroosio", "Erosion"],
	"h-fear": ["Dental fear", "Hammashoitopelko", "Tandvårdsrädsla"],
	"h-gum-disease": ["Gingivitis", "Ientulehdus", "Gingivit"],
	"h-health": ["Dental health", "Suunterveys", "Munhälsa"],
	"h-health-guides": ["Guides for dental health", "Ohjeita suunterveyteen", "Guider för munhälsa"],
	"h-images": ["Images", "Kuvat", "Bilderna"],
	"h-login": ["Log-in", "Kirjaudu sisään", "Logga in"],
	"h-register": ["Create a new user", "Luo uusi käyttäjä", "Skapa ny användare"],
	"h-results": ["Results by date", "Tulokset päivämäärän mukaan", "Resultat efter datum "],
	"h-risk-points": ["Risk points", "Riskipisteesi", "Riskpunkter"],
	
	"img-front": ["Front teeth", "Etuhampaat", "Framtänderna"],
	"img-front-left": ["Left molars", "Vasemmanpuoleiset poskihampaat", "Vänster kindtänderna"],
	"img-front-right": ["Right molars", "Oikeanpuoleiset poskihampaat", "Höger kindtänderna"],
	"img-lower": ["Lower teeth", "Alahampaat", "Tänderna i överkäken"],
	"img-upper": ["Upper teeth", "Ylähampaat", "Tänderna i underkäken"],
	
	"info-information-changed": ["Information changed.", "Tiedot muutettu.", "Informationen har ändrats."],
	"info-password-changed": ["Password changed.", "Salasana vaihdettu.", "Lösenordet har ändrats."],
	"info-uploading": ["Uploading...", "Lähetetään...", "Skickar..."],
	
	"navi-about": ["About", "Tietoa", "Info"],
	"navi-home": ["Home", "Etusivu", "Framsida"],
	"navi-questionnaire": ["Questionnaire", "Kysely", "Enkäten"],
	"navi-results": ["Results", "Tulokset", "Resultat"],
	"navi-settings": ["Settings", "Asetukset", "Inställningar"],
	"navi-feedback": ["Feedback", "Palaute", "Feedback"],
	"navi-logout": ["Logout", "Kirjaudu ulos", "Logga ut"],
	
	"resp-form-failed": ["Form validation failed.", "Lomakkeen vahvistus epäonnistui.", "Formulärverifiering misslyckades."],
	"resp-no-consent": ["No consent.", "Ei suostumusta.", "Inget samtycke. "],
	"resp-no-identification": ["Identification failed.", "Tunnistautuminen epäonnistui.", "Identifieringen misslyckades. "],
	"resp-incorrect-login": ["Incorrect username or password.", "Virheellinen käyttäjätunnus tai salasana.", "Ogiltigt användarnamn eller lösenord."],
	"resp-internal-error": ["Internal server error.", "Sisäinen palvelinvirhe.", "Internt serverfel."],
	"resp-invalid-answers": ["Invalid answers given.", "Virheelliset vastaukset annettu.", "Felaktiga svar ges ."],
	"resp-invalid-auth": ["Invalid authentication type.", "Virheellinen todennustyyppi.", "Ogiltig autentiseringstyp."],
	"resp-invalid-code": ["Invalid registration code.", "Virhellinen rekisteröintikoodi.", "Ogiltig registreringskod. "],
	"resp-invalid-dentist": ["Invalid dentist.", "Virheellinen hammashoitola.", "Ogiltig tandvårdsklinik."],
	"resp-invalid-email": ["Invalid email address.", "Virheellinen sähköpostiosoite.", "Ogiltig e-postadress."],
	"resp-invalid-eval": ["Invalid evaluator.", "Virheellinen evaluaattori.", "Ogiltig evaluator."],
	"resp-invalid-image": ["Invalid image file.", "Virheellinen kuvatiedosto.", "Ogiltig bildfil. "],
	"resp-invalid-phone": ["Invalid phone number.", "Virheellinen puhelinnumero.", "Ogiltigt telefonnummer. "],
	"resp-invalid-ssn": ["Invalid social security number.", "Virheellinen sosiaaliturvatunnus.", "Ogiltigt personbeteckning."],
	"resp-max-answers": ["Too many questionnaire responses.", "Liian monta kyselyn vastauskertaa.", "För många enkätsvar."],
	"resp-questionnaire-notfound": ["Questionnaire not found.", "Kyselyä ei löydy.", "Enkänten hittades inte. "],
	"resp-results-notfound": ["Results not found.", "Vastauksia ei löydy.", "Resultat hittades inte."],
	"resp-unauthorized": ["Unauthorized.", "Ei käyttöoikeuksia.", "Inga tillstånd. "],
	
	"text-about": [textAboutEn, textAboutFi, textAboutSe],
	"text-change-password": ["Change password", "Vaihda salasana", "Ändra lösenord "],
	"text-change-userinfo": ["Change user information", "Muuta käyttäjätietoja", "Ändra användarinformation "],
	"text-cookies": [textCookiesEn, textCookiesFi, textCookiesSe],
	"text-delete-result": ["Delete results?", "Poista tulokset?", "Ta bort resultat?"],
	"text-delete-img": ["Delete image?", "Poista Kuva?", "Ta bort bild?"],
	"text-dentist-info": ["If you are using a public dental service, write the municipality or city name. If you are using something else, write the company or other name.", "Jos käytät julkista hammashoitoa, merkitse kunta tai kaupunki, jos muuta niin merkitse hammashoidon yritys tai muu taho.", "Om du använder den offentliga tandvården, ange kommunen eller staden, om annat ange företaget för tandvården eller annat."],
	"text-feedback-guides": [feedbackGuidesEn, feedbackGuidesFi, feedbackGuidesSe],
	"text-feedback-fear-none": [feedbackFearNoneEn, feedbackFearNoneFi, feedbackFearNoneSe],
	"text-feedback-fear-low": [feedbackFearLowEn, feedbackFearLowFi, feedbackFearLowSe],
	"text-feedback-fear-mid": [feedbackFearMidEn, feedbackFearMidFi, feedbackFearMidSe],
	"text-feedback-fear-high": [feedbackFearHighEn, feedbackFearHighFi, feedbackFearHighSe],
	"text-feedback-risk-low": [feedbackRiskLowEn, feedbackRiskLowFi, feedbackRiskLowSe],
	"text-feedback-risk-mid": [feedbackRiskMidEn, feedbackRiskMidFi, feedbackRiskMidSe],
	"text-feedback-risk-high": [feedbackRiskHighEn, feedbackRiskHighFi, feedbackRiskHighSe],
	"text-feedback-sugar": [feedbackSugarEn, feedbackSugarFi, feedbackSugarSe],
	"text-images": [textImagesEn, textImagesFi, textImagesSe],
	"text-home": [textHomeEn, textHomeFi, textHomeSe],
	"text-no-results": ["No results. Please fill in the questionnaire first.", "Ei tuloksia. Käy täyttämässä kysely ensin.", "Inga resultat. Besvara enkäten först."],
	"text-password-info": ["The password must be at least 8 long.", "Salasanassa tulee olla vähintään 8 merkkiä.", "Lösenordet ska innehålla minst 8 tecken."],
	"text-select-img": ["Select image", "Valitse kuva", "Välj bild"],
	"text-welcome": ["Thank you for wanting to participate in the Digital Leap in Oral Health – Towards a Virtual Reception study.", "Tervetuloa osallistumaan Oulun yliopiston Suunterveyden digiloikka – kohti virtuaalista vastaanottoa tutkimukseen.", "Välkommen att delta i Uleåborgs universitets undersökning Munhälsans digitala språng – mot en virtuell mottagning."],
	"text-warning": ["Please note that the virtual service for oral diseases is still in the research phase. If you have any oral health problems or questions, ask your dentist or dental hygienist.", "Huomioithan, että suusairauksien virtuaalipalvelu on vasta tutkimusvaiheessa. Jos koet, että sinulla on suunterveyteen liittyviä ongelmia tai kysymyksiä, käänny hammaslääkärin tai suuhygienistin puoleen.", "Beakta att den virtuella tjänsten för munsjukdomar ännu är i undersökningsskedet. Om du upplever att du har problem eller frågor som rör munhälsan ska du vända dig till en tandläkare eller munhygienist."],
	"url-feedback": ["https://link.webropolsurveys.com/S/04BE13969DF40CFC", "https://link.webropolsurveys.com/S/04BE13969DF40CFC", "https://link.webropolsurveys.com/S/04BE13969DF40CFC"],
};

export { LANGUAGES, DEFAULT_LANGUAGE, FLAGS, STRINGS };
