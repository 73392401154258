import { BaseComponent } from "./base.js";
import { text, Text } from "./language.js";
import Loading from "./loading.js";
import Sponsors from "./sponsors.js";

import { Button } from "react-bootstrap";

class Home extends BaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			state: null,
			user: null
		};
	}
	
	componentDidMount() {
		this.api.userGet(
			(data) => {
				this.setState({user: data}, () => {
					this.setState({state: "home"});
				});
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	render() {
		switch(this.state.state) {
			case "home":
				return <>
					<Text name="text-home" username={this.state.user.name} />
					<div style={{textAlign: "center"}}>
						<Button onClick={() => {
							if (this.props.onQuestionnaireClick)
								this.props.onQuestionnaireClick();
						}}>
							{text("btn-questionnaire")}
						</Button>
					</div>
					<div style={{marginTop: "50px"}}>
						<Sponsors select={["unioulu", "ppl", "vipuvoimaa", "eakr"]} />
						<Sponsors select={["colgate", "dentview", "mehilainen", "kallio", "kuusamo", "lahitapiola", "tecinspire", "tuudo"]} />
					</div>
				</>;
			default:
				return <Loading />;
		}
	}
}

export default Home;
