import React from "react";

import "./loading.css";

class Loading extends React.Component {
	render() {
		return <div style={{textAlign: "center"}}><div className="spinner"></div></div>;
	}
}

export default Loading;
