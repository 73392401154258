import { text } from "./language.js";

import React from "react";
import { Button } from "react-bootstrap";

const VERSION = "1.0.3";
const DATE = "2022-05-18";

class API {
	constructor(url) {
		this.url = url;
	}
	
	request(page, req, onSuccess, onError) {
		const fullReq = {
			method: req.method,
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			headers: req.headers,
			redirect: "follow",
			referrerPolicy: "no-referrer",
			body: req.body
		};
		fetch(this.url + page, fullReq)
			.then(resp => resp.json())
			.then(
				(obj) => {
					if (obj.resp.status === 200 && onSuccess)
						onSuccess(obj.data);
					else if (onError)
						onError(obj.resp);
				},
				() => {
					if (onError)
						onError({status: 500, reason: "resp-internal-error"});
				}
			);
	}
	
	get(page, onSuccess, onError) {
		this.request(page, {
			method: "GET"
		}, onSuccess, onError);
	}
	
	postForm(page, data, onSuccess, onError) {
		const form = new FormData();
		for (const i in data)
			form.append(i, data[i]);
		this.request(page, {
			method: "POST",
			headers: undefined,
			body: form
		}, onSuccess, onError);
	}
	
	postJSON(page, data, onSuccess, onError) {
		this.request(page, {
			method: "POST",
			headers: {"Content-Type": "application/json"},
			body: JSON.stringify(data)
		}, onSuccess, onError);
	}
	
	delete(page, succ, err) {
		this.request(page, {
			method: "DELETE"
		}, succ, err);
	}
	
	register(data, onSuccess, onError) {
		const url = data.auth === "shib" ? "/shib/register" : "/register";
		this.postForm(url, data, onSuccess, onError);
	}
	
	shibIdentify(onSuccess, onError) {
		const features = "popup=1";
		const win = window.open(this.url + "/shib/identify", "Shibboleth", features);
		const interval = setInterval(() => {
			if (win.closed) {
				this.get("/shib/session", onSuccess, onError);
				clearInterval(interval);
			}
		}, 100);
		return win;
	}
	
	login(data, onSuccess, onError) {
		this.postForm("/login", data, onSuccess, onError);
	}
	
	logout(onSuccess, onError) {
		this.userGet(
			(data) => {
				this.postForm("/logout", {}, onSuccess, onError);
				if (data.auth === "shib")
					this.get("/shib/logout"); // TODO: callbacks
			}, onError
		);
	}
	
	questionnaireGet(qname, onSuccess, onError) {
		this.get("/questionnaire/" + qname, onSuccess, onError);
	}
	
	questionnairePost(qname, data, onSuccess, onError) {
		this.postJSON("/questionnaire/" + qname, data, onSuccess, onError);
	}
	
	resultsList(onSuccess, onError) {
		this.get("/results", onSuccess, onError);
	}
	
	resultsGet(id, ename, onSuccess, onError) {
		this.get("/results/" + id + "/" + ename, onSuccess, onError);
	}
	
	resultsDelete(id, onSuccess, onError) {
		this.delete("/results/" + id, onSuccess, onError);
	}
	
	resultsAttachmentPost(id, name, file, onSuccess, onError) {
		this.postForm("/results/" + id +"/attachments/" + name, {file: file}, onSuccess, onError);
	}
	
	resultsAttachmentDelete(id, name, onSuccess, onError) {
		this.delete("/results/" + id +"/attachments/" + name, onSuccess, onError);
	}
	
	userGet(onSuccess, onError) {
		this.get("/user", onSuccess, onError);
	}
	
	userPassword(data, onSuccess, onError) {
		this.postForm("/user/password", data, onSuccess, onError);
	}
	
	userInfo(data, onSuccess, onError) {
		this.postForm("/user/info", data, onSuccess, onError);
	}
	
	userResult(data, onSuccess, onError) {
		this.postForm("/user/result", data, onSuccess, onError);
	}
}

class BaseComponent extends React.Component {
	constructor(props) {
		super(props);
		this.api = new API(process.env.REACT_APP_API_URL);
	}
	
	throwError(error) {
		if (this.props.onError)
			this.props.onError(error);
	}
}

class Details extends React.Component {
	render() {
		return <details open={Boolean(this.props.open)}>
			<summary>
				{this.props.header}
			</summary>
			<hr />
			{this.props.children}
		</details>;
	}
}

class Document extends React.Component {
	render() {
		return <details open={Boolean(this.props.open)}>
			<summary>
				{this.props.header}
			</summary>
			<hr />
			<p><Button onClick={() => {
				window.open(this.props.file, "_blank").focus();
			}}>
				{text("btn-open-pdf")}
			</Button></p>
			<div className="document">
				{this.props.children}
			</div>
			<hr />
		</details>;
	}
}

class Video extends React.Component {
	render() {
		return <video controls>
			<source src={"static/media/" + this.props.name} type="video/mp4" />
		</video>;
	}
}

class SubmitReset extends React.Component {
	render() {
		return <div style={{float: "right"}}>
			{/*<Button as="input" type="reset" value={text("btn-reset")} />*/}
			<Button as="input" type="submit" value={text("btn-submit")} />
		</div>;
	}
}

export { VERSION, DATE, BaseComponent, Details, Document, Video, SubmitReset };
