import { BaseComponent, Details } from "./base.js";
import Loading from "./loading.js";
import Sponsors from "./sponsors.js";
import { Text, text } from "./language.js";
import { localDateTimeString } from "./util.js";

import React from "react";
import { Button } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "./results.css";

class ScoreSlider extends React.Component {
	render() {
		return <>
			<div style={{width: "100%", textAlign: "center"}}>
				<b>{this.props.title}</b><br />
				<img className="img-slider" src="static/icons/speak-no-evil-monkey.png" alt="bad" />
				<hr className="slider-left" style={{width: 45*(1-this.props.percent) + "%"}} />
				<div className="slider-curs"></div>
				<hr className="slider-right" style={{width: 45*this.props.percent + "%"}} />
				<img className="img-slider" src="static/icons/smiling-face-with-open-mouth.png" alt="good" />
			</div>
			<br />
		</>
	}
}

class Score extends React.Component {
	percent(e) {
		const level = 1.0 / e.levels.length;
		let points = e.points;
		let percent = 0.0;
		for (let i = 0; i < e.levels.length; i++) {
			const delta = e.limits[i+1] - e.limits[i];
			if (points - delta >= 0) {
				points -= delta;
				percent += level;
			}
			else {
				percent += points / delta * level;
				break;
			}
		}
		return percent;
	}
	
	render() {
		const e = this.props.evaluations;
		console.log(e);
		const percents = {
			"caries": this.percent(e["caries"]),
			"gum-disease": this.percent(e["gum-disease"]),
			"erosion": this.percent(e["erosion"])
		};
		const percent = percents["caries"]/3 + percents["gum-disease"]/3 + percents["erosion"]/3;
		return <>
			<h1 style={{textAlign: "center"}}>{Math.round(100 - 100*percent)} / 100</h1>
			<br />
			<ScoreSlider title={text("h-caries")} percent={percents["caries"]} />
			<ScoreSlider title={text("h-gum-disease")} percent={percents["gum-disease"]} />
			<ScoreSlider title={text("h-erosion")} percent={percents["erosion"]} />
		</>;
	}
}

class Attachment extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.state = {
			file: null,
			uploading: false
		};
	}
	
	handleChange(event) {
		this.setState({file: event.target.files[0]});
	}
	
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		
		const file = this.state.file;
		if (!file)
			return;
		
		this.setState({file: null, uploading: true}, () => {
			this.api.resultsAttachmentPost(
				this.props.rid,
				this.props.a.name,
				file,
				(data) => {
					this.setState({uploading: false}, () => {
						if (this.props.onUpdate)
							this.props.onUpdate();
					});
				},
				(error) => {
					this.setState({uploading: false}, () => {
						this.throwError(error);
					});
				}
			);
		});
	}
	
	handleDelete() {
		if (!this.props.a.url)
			return;
		if (!window.confirm(text("text-delete-img")))
			return;
		this.api.resultsAttachmentDelete(
			this.props.rid,
			this.props.a.name,
			(data) => {
				if (this.props.onUpdate)
					this.props.onUpdate();
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	render() {
		const image = this.props.a.url ?
			<TransformWrapper>
				<TransformComponent>
					<img className="img-attachment" src={this.api.url + this.props.a.url} alt={this.props.a.name} />
				</TransformComponent>
			</TransformWrapper>
		:
			<img className="img-attachment" src={"static/media/attachment-" + this.props.a.name + ".png"} alt={this.props.a.name} />;
		
		const del = this.props.a.url ? <Button onClick={this.handleDelete}>{text("btn-delete")}</Button> : null;
		
		const submit = this.state.uploading ? 
			<Text name="info-uploading" /> :
			<Button as="input" type="submit" value={text("btn-submit")} />;
		
		let filename = text("text-select-img");
		if (this.state.file)
			filename = this.state.file.name;
		
		return <div>
			<h2>{text("img-" + this.props.a.name)}</h2>
			{image}
			<span style={{float: "right"}}>
				{del}
			</span>
			<form onSubmit={this.handleSubmit}>
				<label className="file-upload">
					<input style={{display: "none"}} name="file" type="file" onChange={this.handleChange} />
					<Button className="file-upload-btn">{filename}</Button>
				</label>
				{submit}
			</form>
			<br />
		</div>;
	}
}

class Result extends BaseComponent {
	constructor(props) {
		super(props);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.handleSetPrimary = this.handleSetPrimary.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.state = {
			ready: false,
			state: "images",
			result: null
		};
	}
	
	componentDidMount() {
		this.api.resultsGet(
			this.props.id,
			"eval",
			(data) => {
				this.setState({result: data}, () => {
					this.setState({ready: true});
				});
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	handleSetPrimary(rid) {
		this.api.userResult({result: this.state.result.rid}, (data) => {
			this.componentDidMount();
		}, (error) => {
			this.throwError(error);
		});
	}
	
	handleDelete() {
		if (!window.confirm(text("text-delete-result")))
			return;
		this.api.resultsDelete(this.props.id,
			(data) => {
				if (this.props.onDelete)
					this.props.onDelete();
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	findLevels(e) {
		for (const name in e) {
			for (const i in e[name].levels) {
				if (e[name].points >= e[name].limits[i])
					e[name].level = e[name].levels[i];
			}
		}
	}
	
	page() {
		const r = this.state.result;
		const e = r.evaluations;
		switch(this.state.state) {
			case "images":
				const attachments = r.attachments.map((a) => {
					return <Attachment
						key={a.name}
						rid={r.rid}
						a={a}
						onUpdate={this.componentDidMount}
						onError={this.props.onError}
					/>;
				});
				return <>
					<Text name="text-images" />
					<Text name="doc-imaging" />
					<br />
					<Details header={text("h-images")} open={true}>
						<div>{attachments}</div>
					</Details>
				</>;
			case "results":
				let risk = "mid";
				if (e["caries"].level === "low" &&
				    e["erosion"].level === "low" &&
				    e["gum-disease"].level === "low") {
					risk = "low";
				}
				else if (e["caries"].level === "high" ||
				         e["erosion"].level === "high" ||
				         e["gum-disease"].level === "high") {
					risk = "high";
				}
				return <>
					<Details header={text("h-health")} open={true}>
						<div><Score evaluations={e} /></div>
						<p><Text name={"text-feedback-risk-" + risk} e={e} /></p>
						<p><Text name="text-feedback-sugar" points={e["sugar-consumption"].points} /></p>
					</Details>
					<br />
					
					<Details header={text("h-health-guides")} open={true}>
						<Text name="text-feedback-guides" e={e} />
					</Details>
					<br />
					
					<Details header={text("h-fear")} open={true}>
						<Text name={"text-feedback-fear-" + e["dental-fear"].level} points={e["dental-fear"].points} />
					</Details>
					<br />
					
					<p><Text name="text-warning" /></p>
					<br />
					
					<Button className="btn-result" onClick={() => {
						window.open(text("url-feedback")); 
					}}>{text("btn-feedback")}</Button>
					
					<div style={{marginTop: "50px"}}>
						<Sponsors select={["unioulu", "ppl", "vipuvoimaa", "eakr"]} />
						<Sponsors select={["colgate", "dentview", "mehilainen", "kallio", "kuusamo", "lahitapiola", "tecinspire", "tuudo"]} />
					</div>
				</>;
			default:
				return null;
		}
	}
	
	render() {
		if (!this.state.ready)
			return <Loading />;
		
		const r = this.state.result;
		let setPrimaryBtn = null;
		if (!r.primary) {
			setPrimaryBtn = <Button onClick={this.handleSetPrimary}>
				{text("btn-set-primary")}
			</Button>;
		}
		const content = this.page();
		return <>
			<span style={{float: "right"}}>{localDateTimeString(r.time)}</span>
			{setPrimaryBtn}
			{/*<Button onClick={this.handleDelete}>
				{text("btn-delete")}
			</Button>*/}
			<br />
			<br />
			<div style={{textAlign: "center"}}>
				<Button className="btn-result-type" onClick={() => {
					this.setState({state: "images"});
				}}>{text("btn-images")}</Button>
				<Button className="btn-result-type" onClick={() => {
					this.setState({state: "results"});
				}}>{text("btn-results")}</Button>
			</div>
			<br />
			{content}
		</>;
	}
}

class ResultsList extends BaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			state: null,
			list: null
		};
	}
	
	componentDidMount() {
		this.api.resultsList(
			(data) => {
				this.setState({list: data.results}, () => {
					this.setState({state: "ready"});
				});
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	render() {
		switch(this.state.state) {
			case "ready":
				const results = this.state.list.map((r) => {
					let dt = localDateTimeString(r.time);
					if (r.primary)
						dt = <b>{dt}</b>;
					
					return <li className="li-result" key={r.rid}>
						<Button className="btn-result" onClick={() => {
							this.props.onSelect(r.rid);
						}}>
							{dt}
						</Button>
					</li>;
				});
				if (results.length === 0)
					return <>{text("text-no-results")}</>;
				return <>
					<h2>{text("h-results")}</h2>
					<ol className="ol-results">
						{results}
					</ol>
				</>;
			default:
				return <Loading />;
		}
	}
}

class Results extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {
			state: this.props.state ? this.props.state : null,
			rid: this.props.rid ? this.props.rid : null
		};
	}
	
	handleSelect(rid) {
		this.setState({rid: rid}, () => {
			this.setState({state: "single"});
		});
	}
	
	stateSingle() {
		return <>
			<Button onClick={() => {
				this.setState({state: "list"});
			}}>
				{text("btn-back")}
			</Button>
			<br />
			<br />
			<Result
				id={this.state.rid}
				onDelete={() => {
					this.setState({state: "list"});
				}}
				onError={this.props.onError}
			/>
		</>;
	}
	
	stateList() {
		return <ResultsList
			onError={this.props.onError}
			onSelect={this.handleSelect}
		/>;
	}
	
	render() {
		switch(this.state.state) {
			case "list":
				return this.stateList();
			case "single":
				return this.stateSingle();
			default:
				return this.stateList();
		}
	}
}

export default Results;
