import { getLang } from "./language.js";

import React from "react";
import Cookies from "universal-cookie";

const COOKIES_PREFIX = "dentogram-react-";

function copy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

function timestamp() {
	return Math.floor(Date.now() / 1000);
}

function localDateTime(unixtime) {
	const offset = (new Date()).getTimezoneOffset() * 60;
	return new Date((unixtime - offset) * 1000);
}

function localDateTimeString(unixtime) {
	const lang = getLang();
	const locale = lang.toLowerCase() + "-" + lang.toUpperCase();
	return localDateTime(unixtime).toLocaleString(locale);
}

class ParseHTML extends React.Component {
	render() {
		const tag = this.props.tag ? this.props.tag : "span";
		return React.createElement(tag, {dangerouslySetInnerHTML: {__html: this.props.html}});
	}
}

function getCookie(name) {
	return (new Cookies()).get(COOKIES_PREFIX + name);
}

function setCookie(name, val) {
	(new Cookies()).set(COOKIES_PREFIX + name, val, {
		path: "/",
		sameSite: "strict"
	});
}

export { copy, timestamp, localDateTime, localDateTimeString, ParseHTML, setCookie, getCookie };
