import React from "react";

import "./sponsors.css";

const SPONSORS = {
	"eakr": ["eakr.png", "", true],
	"unioulu": ["unioulu.jpg", "https://www.oulu.fi", false],
	"ppl": ["ppl.png", "https://www.pohjois-pohjanmaa.fi", false],
	"vipuvoimaa": ["vipuvoimaa.png", "", false],
	"colgate": ["colgate.png", "https://www.colgate.fi", false],
	"dentview": ["dentview.png", "https://www.dentview.fi", false],
	"mehilainen": ["mehilainen.jpg", "https://www.mehilainen.fi/hammasmehilainen", false],
	"kallio": ["kallio.jpg", "https://www.kalliopp.fi", false],
	"kuusamo": ["kuusamo.png", "https://www.kuusamo.fi/", false],
	"lahitapiola": ["lahitapiola.png", "https://www.lahitapiola.fi", false],
	"tecinspire": ["tecinspire.png", "https://www.tecinspire.com/", false],
	"tuudo": ["tuudo.png", "https://www.tuudo.fi", false]
};

class Sponsor extends React.Component {
	render() {
		const className = this.props.featured ? "img-sponsor-featured" : "img-sponsor"
		const img = <img className={className} src={"static/logos/" + this.props.img} alt={this.props.alt} />;
		if (this.props.url) {
			return <a href={this.props.url} target="_blank" rel="noreferrer">
				{img}
			</a>;
		}
		else {
			return img;
		}
	}
}

class Sponsors extends React.Component {
	render() {
		const keys = this.props.select ? this.props.select : Object.keys(SPONSORS);
		const sponsors = keys.map((key) => {
			const s = SPONSORS[key];
			return <li className="li-sponsor" key={s}>
				<Sponsor img={s[0]} alt={s[0]} url={s[1]} featured={s[2]} />
			</li>
		});
		return <ol className="ol-sponsors">{sponsors}</ol>;
	}
}

export default Sponsors;
