import { BaseComponent, SubmitReset } from "./base.js";
import Loading from "./loading.js";
import { text, Text } from "./language.js";

import React from "react";
import { Button, Form } from "react-bootstrap";

class LoginForm extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			loading: false,
			name: "",
			password: ""
		}
	}
	
	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		
		this.setState({loading: true}, () => {
			this.api.login({
					name: this.state.name.toUpperCase(),
					password: this.state.password
				},
				(data) => {
					this.setState({loading: false}, () => {
						this.props.onSuccess(data);
					});
				},
				(error) => {
					this.setState({loading: false}, () => {
						this.throwError(error);
					});
				}
			);
		});
	}
	
	render() {
		if (this.state.loading)
			return <Loading />;
		return <form onSubmit={this.handleSubmit}>
			<input name="name" type="text" className="input-text" placeholder={text("field-username")} onChange={this.handleChange} /><br />
			<input name="password" type="password" className="input-text" placeholder={text("field-password")} onChange={this.handleChange} /><br />
			<SubmitReset />
		</form>;
	}
}

class RegisterFormTest extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			loading: false,
			password: "",
			confirm: ""
		};
	}
	
	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		
		if (this.state.password !== this.state.confirm) {
			this.throwError({status: 400, reason: "error-password-match"});
			return;
		}
		
		const data = {
			auth: "test",
			password: this.state.password
		}
		this.setState({loading: true}, () => {
			this.api.register(data,
				(data) => {
					this.setState({loading: false}, () => {
						this.props.onSuccess(data);
					});
				},
				(error) => {
					this.setState({loading: false}, () => {
						this.throwError(error);
					});
				}
			);
		});
	}
	
	render() {
		if (this.state.loading)
			return <Loading />;
		return <form onSubmit={this.handleSubmit}>
			{text("text-password-info")}<br />
			<input name="password" type="password" className="input-text" placeholder={text("field-password")} onChange={this.handleChange} /><br />
			<input name="confirm" type="password" className="input-text" placeholder={text("field-confirm-password")} onChange={this.handleChange} /><br />
			<SubmitReset />
		</form>;
	}
}

class RegisterFormCode extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			loading: false,
			password: "",
			confirm: "",
			code: "",
			ssn: "",
			phone: "",
			email: "",
			dentist: ""
		};
	}
	
	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		
		if (this.state.password !== this.state.confirm) {
			this.throwError({status: 400, reason: "error-password-match"});
			return;
		}
		
		let data = {
			auth: "code",
			password: this.state.password,
			consent: true,
			code: this.state.code,
			ssn: this.state.ssn
		}
		if (this.state.phone)
			data.phone = this.state.phone.replace(/\s/g, "");
		if (this.state.email)
			data.email = this.state.email;
		if (this.state.dentist)
			data.dentist = this.state.dentist;
		
		this.setState({loading: true}, () => {
			this.api.register(data,
				(data) => {
					this.setState({loading: false});
					this.props.onSuccess(data);
				},
				(error) => {
					this.throwError(error);
					this.setState({loading: false});
				}
			);
		});
	}
	
	render() {
		if (this.state.loading) {
			return <Loading />;
		}
		return <form onSubmit={this.handleSubmit}>
			{text("text-password-info")}<br />
			<input name="password" value={this.state.password} type="password" className="input-text" placeholder={text("field-password")} onChange={this.handleChange} /><br />
			<input name="confirm" value={this.state.confirm} type="password" className="input-text" placeholder={text("field-confirm-password")} onChange={this.handleChange} /><br />
			<input name="code" value={this.state.code} type="text" className="input-text" placeholder={text("field-code")} onChange={this.handleChange} /><br />
			<input name="ssn" value={this.state.ssn} type="text" className="input-text" placeholder={text("field-ssn")} onChange={this.handleChange} /><br />
			<input name="phone" value={this.state.phone} type="text" className="input-text" placeholder={text("field-phone")} onChange={this.handleChange} /><br />
			<input name="email" value={this.state.email} type="text" className="input-text" placeholder={text("field-email")} onChange={this.handleChange} /><br />
			{text("text-dentist-info")}<br />
			<input name="dentist" value={this.state.dentist} type="text" className="input-text" placeholder={text("field-dentist")} onChange={this.handleChange} /><br />
			<SubmitReset />
		</form>;
	}
}

class ConsentFormShib extends React.Component {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			acceptPrivacy: false,
			acceptResearch: false
		}
	}
	
	handleChange(event) {
		this.setState({[event.target.name]: event.target.checked});
	}
	
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		if (this.props.onAccept)
			this.props.onAccept();
	}
	
	render() {
		const disabled = !this.state.acceptPrivacy || !this.state.acceptResearch;
		return <>
			<Text name={"doc-consent"} open={true} />
			<br />
			<form onSubmit={this.handleSubmit}>
				<Form.Check inline name="acceptPrivacy" label={text("btn-accept-privacy")} onChange={this.handleChange} /><br />
				<Form.Check inline name="acceptResearch" label={text("btn-accept-study")} onChange={this.handleChange} /><br />
				<div style={{textAlign: "center"}}>
					<Button disabled={disabled} as="input" type="submit" value={text("btn-next")} />
				</div>
			</form>
		</>;
	}
}

class RegisterFormShib extends BaseComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.identify = this.identify.bind(this);
		this.state = {
			state: "consent",
			password: "",
			confirm: "",
			consent: false,
			phone: "",
			email: "",
			dentist: ""
		};
	}
	
	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}
	
	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		
		if (this.state.password !== this.state.confirm) {
			this.throwError({status: 400, reason: "error-password-match"});
			return;
		}
		
		let data = {
			auth: "shib",
			password: this.state.password,
			consent: this.state.consent
		}
		if (this.state.phone)
			data.phone = this.state.phone.replace(/\s/g, "");
		if (this.state.email)
			data.email = this.state.email;
		if (this.state.dentist)
			data.dentist = this.state.dentist;
		
		this.setState({loading: true}, () => {
			this.api.register(data,
				(data) => {
					this.setState({loading: false});
					this.props.onSuccess(data);
				},
				(error) => {
					this.throwError(error);
					this.setState({loading: false});
				}
			);
		});
	}
	
	identify() {
		this.api.shibIdentify(
			(data) => {
				this.setState({state: "forms"});
			},
			(error) => {
				this.throwError(error);
			}
		);
	}
	
	render() {
		switch (this.state.state) {
			case "consent":
				return <ConsentFormShib onAccept={() => {
					this.setState({consent: true});
					this.identify();
				}} />
			case "forms":
				return <form onSubmit={this.handleSubmit}>
					{text("text-password-info")}<br />
					<input name="password" value={this.state.password} type="password" className="input-text" placeholder={text("field-password")} onChange={this.handleChange} /><br />
					<input name="confirm" value={this.state.confirm} type="password" className="input-text" placeholder={text("field-confirm-password")} onChange={this.handleChange} /><br />
					<input name="phone" value={this.state.phone} type="text" className="input-text" placeholder={text("field-phone")} onChange={this.handleChange} /><br />
					<input name="email" value={this.state.email} type="text" className="input-text" placeholder={text("field-email")} onChange={this.handleChange} /><br />
					{text("text-dentist-info")}<br />
					<input name="dentist" value={this.state.dentist} type="text" className="input-text" placeholder={text("field-dentist")} onChange={this.handleChange} /><br />
					<SubmitReset />
				</form>;
			default:
				return <Loading />;
		}
	}
}

class Auth extends React.Component {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {
			state: "select",
			auth: "shib"
		};
		this.allowTest = process.env.REACT_APP_ALLOW_TEST_ACCOUNT === "true";
	}
	
	handleLoginClick() {
		this.setState({state: "login"});
	}
	
	handleRegisterClick() {
		this.setState({state: "register"});
	}
	
	handleSelect(event) {
		this.setState({auth: event.target.value});
	}
	
	getRegister() {
		switch (this.state.auth) {
			case "code":
				return <RegisterFormCode onSuccess={this.props.onSuccess} onError={this.props.onError} />;
			case "shib":
				return <RegisterFormShib onSuccess={this.props.onSuccess} onError={this.props.onError} />;
			default:
				return <RegisterFormTest onSuccess={this.props.onSuccess} onError={this.props.onError} />;
		}
	}
	
	render() {
		switch (this.state.state) {
			case "select":
				return <>
					<h2>{text("h-register")}</h2>
					<select className="input-text" onChange={this.handleSelect} value={this.state.auth}>
						{this.allowTest ? <option value="test">{text("field-test-account")}</option> : null}
						<option value="code">{text("field-code-account")}</option>
						<option value="shib">{text("field-shib-account")}</option>
					</select>
					<div style={{textAlign: "right"}}>
						<Button onClick={() => {
							this.setState({state: "register"});
						}}>
							{text("btn-next")}
						</Button>
					</div>
					<br />
					
					<h2>{text("h-login")}</h2>
					<LoginForm onSuccess={this.props.onSuccess} onError={this.props.onError} />
				</>;
			case "register":
				return <>
					<Button onClick={() => {
						this.setState({state: "select"});
					}}>{text("btn-back")}</Button>
					<br />
					<br />
					
					{this.getRegister()}
				</>;
			default:
				return null;
		}
	}
}

export default Auth;
